import { gql } from '@apollo/client';

const UPLOAD_FILE_MUTATION = gql`
  mutation SingleUpload($file: Upload, $runTextract: Boolean!) {
    singleUpload(file: $file, runTextract: $runTextract) {
      filename
      mimetype
      encoding
      url
      extractedData {
        firstName
        lastName
        middleName
        suffix
        cityInAddress
        zipCodeInAddress
        stateInAddress
        stateName
        documentNumber
        expirationDate
        dateOfBirth
        dateOfIssue
        idType
        endorsements
        veteran
        restrictions
        class
        address
        county
        sex
      }
      vinData{
        vin
      }
      document{
        _id
        s3Reference{
          s3Bucket
          s3Key
          s3URL
        }
      }
    }
  }
`;

export {UPLOAD_FILE_MUTATION};
