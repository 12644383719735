import { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { UPLOAD_FILE_MUTATION } from '../../queries/uploadFileMutation';
import { UPDATE_EMPLOYEE } from '../../queries/updateEmployee';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { v4 as uuidv4 } from 'uuid';


const UploadDocumentForm = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileBlob, setFileBlob] = useState<Blob | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

  const handleFileToBlob = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        const blob = new Blob([reader.result], { type: file.type });
        setFileBlob(blob);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Handle file drop
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      setSelectedFile(file);
      handleFileToBlob(file);
    }
  };

  // Handle drag over event
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  // Handle drag leave event
  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  };

  // Handle file selection via input
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      handleFileToBlob(file);
    }
  }

  const {id} = useParams();
  console.log(id);
 const userId = '6716893429508ce78d240b15' 
 console.log(userId);
  const handleUpload = async () => {
    if (!fileBlob) {
      console.error("No file selected");
      return;
    }

    setLoading(true);
    try {
      const { data: uploadData } = await uploadFile({
        variables: {
          file: fileBlob,
          runTextract: false,
        },
      });

      const document = uploadData.singleUpload.document;

      // Hardcoded userId
      // Replace with the actual user ID as needed

      // Update employee with the document reference
      const updateResponse = await updateEmployee({
        variables: {
          id: id,
          input: {
            documentReference: [
              {
                documentType: 'UPLOADED FROM DESKTOP',
                documentId: document._id,
                s3Bucket: document.s3Reference.s3Bucket,
                s3Key: document.s3Reference.s3Key,
                s3Url: document.s3Reference.s3URL,
              },
            ],
          },
        },
      });

      console.log("Employee updated successfully:", updateResponse);
    } catch (error) {
      console.error("Error uploading file or updating employee:", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-full">
      {/* Title */}
      <h2 className="text-xl font-bold text-gray-700 my-4">Add A New Document</h2>

      {/* File Upload Section with Drag-and-Drop */}
      <div
        className={`border-dashed border-2 rounded-lg p-4 w-full max-w-lg text-center ${
          isDragging ? 'border-indigo-600' : 'border-gray-300'
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {selectedFile ? (
          <div>
            <p className="text-gray-500">File selected: {selectedFile.name}</p>
          </div>
        ) : (
          <>
            {/* Plus Icon */}
            <br/>
            <div className="flex justify-center items-center">
              <PlusIcon className="h-12 w-12 text-gray-400" />
            </div>
            <br/>
            <label htmlFor="file-upload" className="block text-sm font-medium text-gray-700">
              {isDragging ? 'Drop your file here' : 'Drag your file Here'}
            </label>
            <br/>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleFileChange}
            />
          </>
        )}
      </div>

      {/* Browse Link Section */}
      <label
        htmlFor="file-upload"
        className="block text-gray-400 mt-4 cursor-pointer hover:underline"
      >
        Or Browse Your Files Here
      </label>
      <button
        onClick={handleUpload}
        className="mt-4 bg-blue-500 text-white py-2 px-4 rounded update-btn"
        disabled={loading || !fileBlob}
        style={{cursor:'pointer'}}
      >
        {loading ? "Uploading..." : "Upload Document"}
      </button>
    </div>
  );
};

export default UploadDocumentForm;
