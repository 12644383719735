import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import CHECK_SESSION from '../../queries/checkSession';

interface ProtectedRouteProps {
    children: React.ReactNode;
    // allowedRoles: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
   const location = useLocation();
    const { data, loading, error } = useQuery(CHECK_SESSION, {
        fetchPolicy: 'network-only', // Ensure the request is always sent to the server
    });
console.log("data", data)
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error || !data?.checkSession.isAuthenticated) {
        // Check if the current path is a mobile path (starts with /mobileDriverOnboard)
        if (location.pathname.startsWith('/mobileDriverOnboard')) {
            //currently redirecting to landing page -- for future dev redirect to an authentication error page 
            return <Navigate to="/MobileDriverOnboard" replace />;
        } else {
            return <Navigate to="/" replace />;
        }
    }

    // Check if the user's role is in the allowedRoles array
    // if (!allowedRoles.includes(data.checkSession.role)) {
    //     return <Navigate to="/unauthorized" replace />; // Redirect to unauthorized page if role is not allowed
    // }

    // return <>{children}</>;

    const userId = data.checkSession.userId; // Extract userId from session data

    // Pass userId to the children components as a prop
    return React.cloneElement(children as React.ReactElement<any>, { userId });
};

export default ProtectedRoute;
