import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MainTemplate from '../components/mainTemplate';
import ListTemplate from '../components/listTemplate';
import AddButton from '../components/addButton';
import BaseSlideOver from '../components/baseSlideOver';
import { UserPlusIcon } from '@heroicons/react/20/solid';

import { ReactComponent as EmployeesIcon } from '../assets/employees-list.svg';

import { useQuery } from '@apollo/client';
import GET_ALL_EMPLOYEES from '../queries/getAllEmployees';
import GET_LOCATION_BY_NAME from "../queries/getLocationByName";

import formatDate from '../helpers/formatDate';
import { useUser } from '../context/userContext';
import { set } from 'date-fns';


const EmployeePage = () => {
    const nav = useNavigate();
    const [rowClicked, setRowClicked] = useState({});
    const [rowData, setRowData] = useState<Array<{ [key: string]: any }>>([]);
    const [sortByOutlierCount, setSortByOutlierCount] = useState<string | null>(null);
    const [sortByFfdStatus, setSortByFfdStatus] = useState<string | null>(null);
    const [locations, setLocations] = useState<LocationItem[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
    const [addActiveButton, setAddActiveButton] = useState(false);
    // const [locationDetails, setLocationDetails] = useState(null); // State variable to hold location details

    const { userRole } = useUser();

    const { loading, error, data } = useQuery(GET_ALL_EMPLOYEES, {
        variables: {
            filter: {
                locations: selectedLocation,
                outlierCount: sortByOutlierCount,
                ffdStatus:sortByFfdStatus
            },
            skip: 0
        }
    });

    const { loading: locationLoading, data: locationData, refetch: refetchLocation } = useQuery(GET_LOCATION_BY_NAME, {
        skip: true,
    });

    // Function to display option to add driver
    const handleAddButtonClick = () => {
        setAddActiveButton(true)
    };

    // function to display option to toggle slide over
    const onAddDriverClick = () => {
        setPopoverVisible(!popoverVisible);
        setAddActiveButton(false);
        setIsSlideOverOpen(true);
    }


    //matching location by name on click to navigate to location detail page
    useEffect(() => {
        if (locationData && locationData.getLocationByName) {
            const locationId = locationData.getLocationByName._id;
            // setLocationDetails(locationId);
            nav(`/detailLocation/${locationId}`);
        }
    }, [locationData, nav]);

    interface Employee {
        _id: string;
        location: string;
        userId: string;
        firstName: string;
        middleName?: string;
        lastName: string;
        addressStreet: string;
        addressCity: string;
        addressState: string;
        addressZip: string;
        phone: string;
        ffdStatus: string;
        outlierCount: number;
        emailCompany: string;
        driversLicenseNum: string;
        driversLicenseExpiration: string;
        locationReference: [{
            name: string;
            locManagerName: string;
        }];
    }

    interface LocationItem {
        label: string;
        href: string;
    }

    interface CellRendererParams {
        value: any;
    }

    useEffect(() => {
        if (data && data.getAllEmployees) {
            setUniqueLocations(data.getAllEmployees);
            const formattedData = data.getAllEmployees.map((employee: Employee) => {
                let outlierDisplay;
                if (typeof employee.outlierCount === "number") {
                    const totalOutliers = employee.outlierCount;

                    if (totalOutliers > 0) {
                        outlierDisplay = `${employee.outlierCount}`;
                    } else {
                        outlierDisplay = "No Outliers";
                    }
                } else {
                    outlierDisplay = "No Outliers";
                }

                return {
                    id: employee._id,
                    "DRIVER NAME": `${employee.firstName} ${employee.lastName}`,
                    "LOCATION": employee.location,
                    "PHONE": employee.phone,
                    "EMAIL": employee.emailCompany,
                    "DRIVERS LICENSE": employee.driversLicenseNum,
                    "DL EXPIRATION": formatDate(employee.driversLicenseExpiration),
                    "MANAGER":employee.locationReference[0].locManagerName,
                };
            });

            // Sort formattedData by "DRIVER NAME"
            const sortedData = [...formattedData].sort((a, b) => a["DRIVER NAME"].localeCompare(b["DRIVER NAME"]));
            setRowData(sortedData);
        }
    }, [data]);

    const pageTitle = "Drivers";

    const columnDefs = [
        { field: 'DRIVER NAME', sortable: true, sort:'asc', sortIndex: 0,
            comparator: (valueA: string, valueB: string) => {
              const lastNameA = valueA.split(' ').slice(-1)[0]; // Get the last word (last name)
              const lastNameB = valueB.split(' ').slice(-1)[0];
              
              if (lastNameA === lastNameB) return 0;
              return lastNameA > lastNameB ? 1 : -1;
            },flex: 1, cellClass: 'underline-location' },
        ...(userRole !== 'Manager' ? [
            {
                field: 'LOCATION',
                flex: 1,
                cellRenderer: (params: CellRendererParams) => (
                    <button className='underline-location' onClick={() => handleLocationClick(params.value)}>{params.value}</button>
                ),
            }
        ] : []),
        { field: 'PHONE', flex: 1 },
        { field: 'EMAIL', flex: 1 },
        { field: 'DRIVERS LICENSE', flex: 1 },
        { field: 'DL EXPIRATION', flex: 1 },
        { field: 'MANAGER', flex: 1 },
    ];

    const menuItems = {
        menuItemsOne: [
            { label: 'All Locations', href: '/' },
        ],
        menuItemsThree: [
            { label: 'All', href: '/' },
            { label: 'ACTIVE', href: '/' },
            { label: 'INACTIVE', href: '/' },
            { label: 'PENDING', href: '/' },
            { label: 'IN PROCESS', href: '/' }
        ],
    };

    const dropDownData = [
        // Only pass the LOCATIONS dropdown if the user is not a Manager
        ...(userRole !== 'Manager' ? [{
            title: "LOCATIONS",
            menuItems: locations
        }] : []),
        {
            title: "STATUS",
            menuItems: menuItems.menuItemsThree
        },
        {
            title: "LOCATIONS",
            menuItems: locations
        } 
    ];

    const setUniqueLocations = (employees: any) => {
        // Check for locationReference and name before adding to locationStrings
        console.log('employees:', employees);
        const locationStrings: string[] = employees
            .map((employee: Employee) => employee.locationReference?.[0]?.name)
            .filter((location: any) => location !== undefined); // Filter out undefined values
        
        console.log('locationStrings:', locationStrings); // Should now log only defined location names
    
        // Convert to a unique, sorted array of location names
        const uniqueLocationsArray: string[] = Array.from(new Set(locationStrings)).sort();
    
        const allLocationsItem: LocationItem = { label: "All Locations", href: '/' };
    
        // Build the locations array for dropdown
        const uniqueLocations = [
            allLocationsItem,
            ...uniqueLocationsArray.map((location) => {
                return { label: location, href: '/' };
            })
        ];
    
        setLocations(uniqueLocations);
    };
    

    const handleRowClick = (event: any) => {
        setRowClicked(event.data.id);
        nav(`/employeedetail/${event.data.id}`, { state: { data: rowClicked } })
    }

    const handleLocationClick = async (locationName: string) => {
        try {
            const { data: locationData } = await refetchLocation({ name: locationName });
            if (locationData && locationData.getLocationByName) {
                const locationId = locationData.getLocationByName._id;
                // setLocationDetails(locationId);
                nav(`/detailLocation/${locationId}`);
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };

    const handleLocationChange = (location: any) => {
        if (location === "All Locations") {
            // If "All Locations" is selected, reset the filter
            setSelectedLocation(null);
        } else {
            // Otherwise, set the selected location as usual
            setSelectedLocation(location);
            console.log('location:', location);
        }
        console.log('New Location:', location);
    };

    const handleFitForDutySelection = (ffdStatus: string) => {
        if (ffdStatus === "All") {
            // setSortByOutlierCount(null);
            setSortByFfdStatus(null);
        } else {
            // setSortByOutlierCount(outlierCount);
            setSortByFfdStatus(ffdStatus);
        }
    };
    console.log('sortByFfdStatus:', sortByFfdStatus);

    // Filter reset logic--when user clicks the x button on the active filter
    // known bug here--when user clicks x--rowDataOne is displayed for all employees, ignoring the other "active filters"
    const handleFilterReset = () => {
        setSortByOutlierCount(null);
        setSelectedLocation(null);
        setSortByFfdStatus(null);
    };

    const closeSlideOver = () => {
        setIsSlideOverOpen(false);
    };
    const closeAddButtonOverlay = () => {
        setAddActiveButton(false)
    }

    return (
        <div>
            <MainTemplate />
            <ListTemplate
                rowDataOne={rowData}
                handleRowClick={handleRowClick}
                // pageTitle={<Link to="/employeePage">{pageTitle}</Link>}
                pageTitle={pageTitle}
                columnDefs={columnDefs}
                dropDownData={dropDownData}
                onOutlierSelection={handleFitForDutySelection}
                onLocationSelected={handleLocationChange}
                handleFilterReset={handleFilterReset}
                mainIcon={<EmployeesIcon />}
            />
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-end',justifyContent:'flex-end', alignContent:'flex-end', position:'absolute', bottom:'10%', left:'85%',  }}>
               
            {addActiveButton && <div className="dark-overlay" onClick={closeAddButtonOverlay}></div>}
            {addActiveButton && (
                <div style={{ 
                    display:'flex', 
                    bottom:'-4px',
                    right:'35px',
                    zIndex:'1001',
                    width:'12rem', 
                    position:'absolute'
            }}>
                <div style={{ display: "flex", gap: '.5rem', flexDirection:'row-reverse'}}>
                    <div style={{ position: 'relative' }}>
                        <button
                        onClick={onAddDriverClick}
                        className=""
                        style={{
                            borderRadius: "50%",
                            backgroundColor: "#f5f5f5",
                            padding: "1rem",
                            width: "50px", // Ensures the button is circular
                            height: "50px", // Ensures the button is circular
                            display: "flex", // Align icon
                            alignItems: "center", // Center vertically
                            justifyContent: "center", // Center horizontally
                            transition: "transform 1s ease", // Smooth transition for scaling
                            position:'absolute'
                        }}
                        onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.1)'} // Enlarge on hover
                        onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'} // Return to normal size
                        >
                        <UserPlusIcon style={{ width: "20px", height: "20px" }} />
                        </button>
                    </div>
                    <button
                    className="additional-button"
                    style={{
                        display: "flex", // Align text
                        justifyContent: "center", // Center text horizontally
                        alignItems: "center", // Center text vertically
                        width: "110px", // Adjust as needed
                        height: "50px", // Ensure it matches the circular button
                        marginLeft: '3rem',
                    }}
                    >
                    Add Driver
                    </button>
                </div>
            </div>
            )} 
            <div style={{ position: 'absolute', bottom: 0, left: 0 }} >
                <AddButton onClick={handleAddButtonClick} />
            </div >
            
            </div>
            
            
            <BaseSlideOver title={'ONBOARD A NEW DRIVER'} closePopover={closeSlideOver} isOpen={isSlideOverOpen}
            />
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error.toString()}</p>
            ) : (
                <p>list</p>
            )}
        </div>
    );
};

export default EmployeePage;
