import { useState } from 'react';
import { useMutation } from '@apollo/client';
import SEND_CUSTOM_EMAIL from '../../queries/sendCustomEmail';
import { useUser } from '../../context/userContext';

const EmailForm = (employeeEmail: any) => {
  // Form state for email input fields
  console.log('employeeEmail:', employeeEmail);
  const [formData, setFormData] = useState({
    cc: '',
    subject: '',
    message: '',
  });

  const recipientEmail = employeeEmail.employeeEmail;
  const { email } = useUser();

  const senderEmail = email;
  // Set up the mutation hook
  const [sendCustomEmail, { data, loading, error }] = useMutation(SEND_CUSTOM_EMAIL);

  // Input change handler for form fields
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Submit handler to send the email
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await sendCustomEmail({
        variables: {
          recipientEmail,
          senderEmail,
          subject: formData.subject,
          bodyContent: formData.message,
        },
      });
      console.log('Email sent:', data?.sendCustomEmail.message);
    } catch (err) {
      console.error('Error sending email:', err);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">CC</label>
        <input
          type="email"
          name="cc"
          value={formData.cc}
          onChange={handleInputChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter CC email"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Subject</label>
        <input
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleInputChange}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter Subject Line"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleInputChange}
          rows={4}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder="Enter your message here"
        ></textarea>
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          disabled={loading}
          className="mt-4 bg-blue-500 text-white py-2 px-4 rounded update-btn"
        >
          {loading ? 'Sending...' : 'Send Email'}
        </button>
      </div>
      {data && <p className="text-gray-600 mt-4">{data.sendCustomEmail.message}</p>}
      {error && <p className="text-red-600 mt-4">Error: {error.message}</p>}
    </form>
  );
};

export default EmailForm;
