import { useState, ChangeEvent, FormEvent } from 'react';
import { Dialog } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';

interface OtpModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleOtpSubmit: (otp: string) => void;
  error?: string;
}

const OtpModal: React.FC<OtpModalProps> = ({ open, setOpen, handleOtpSubmit, error }) => {
  const [otp, setOtp] = useState<string>('');  // State to store OTP value
  const [otpError, setOtpError] = useState<string>('');  // State for error message

  const handleOtpChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);  // Update OTP state as user types
  };

  const submitOtp = (e: FormEvent) => {
    e.preventDefault();
    if (!otp) {
      setOtpError('OTP is required');
    } else {
      setOtpError('');
      handleOtpSubmit(otp);  // Call parent function to validate OTP
    }
  };

  return (
    <Dialog open={open} onClose={() => {}} className="relative z-10"> {/* Prevent modal close on backdrop click */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-0 transition-opacity" aria-hidden="true" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {/* <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
          > */}
          {/* <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
          > */}
          {/* <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
              backdropFilter: 'blur(5px)', // Apply the blur effect to the modal
              borderRadius: '10px', // Rounded corners for the glass effect
              border: '1px solid rgba(255, 255, 255, 0.3)', // Subtle border for better glass-like effect
            }}
          > */}
          <Dialog.Panel
            className="relative transform overflow-hidden rounded-lg bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-lg sm:p-6"
            style={{
              // backgroundColor: 'rgba(255, 255, 255, 0.2)', // Semi-transparent background
              // backdropFilter: 'blur(6px)', // Apply the blur effect to the modal
              backgroundColor:"white",
              borderRadius: '10px', // Rounded corners for the glass effect
              border: '0px solid transparent', // Transparent border to show the gradient
              backgroundClip: 'padding-box', // Ensure the border is visible only outside the content
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.4)', // Subtle shadow for depth
              borderImage: 'linear-gradient(45deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1)) 1', // Shiny gradient effect
              animation: 'shine 3s ease-in-out infinite', // Add animation for the reflective effect
            }}
          >
            <div>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
              </div>
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  Enter One Time Passcode to Proceed
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Please enter the 6-digit passcode that was sent to your email to continue.
                  </p>
                </div>
              </div>
            </div>
            {/* OTP Input Field */}
            <form onSubmit={submitOtp}>
              <div className="mt-4">
                <label htmlFor="otp" className="block text-sm font-medium leading-6 text-gray-900">
                  One-Time Passcode
                </label>
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  onChange={handleOtpChange}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Enter Code"
                />
                {otpError && <p className="mt-2 text-sm text-red-600">{otpError}</p>}
              </div>
              {/* Display error message if it exists */}
              {error && <p className="text-red-500 text-sm mt-2">{error}</p>} 

              <div className="mt-5 sm:mt-6">
                <button
                  type="submit"
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default OtpModal;
