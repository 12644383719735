//slide over for dashboard view to approve a driver
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import './rightpopover.css';

import Notification from '../notification';
import BadgeFlatWithDot from '../badgeFlatWithDot';
import SlideOverNav from '../slideOverNav';
import RequiredInfoComponent from '../requiredInfoDash';
import DocumentDisplay from '../documentDisplayRightSlide';

import { useMutation } from '@apollo/client';
import { UPDATE_EMPLOYEE } from '../../queries/updateEmployee';
import { UPDATE_PROGRAM } from '../../queries/updateProgram';
import { useNavigate } from "react-router-dom";


interface NavItem {
  label: string;
  viewId: string;
}

interface RightPopoverProps {
  closePopover: () => void; 
  title: string;
  emailCompany: string;
  phone: string;
  name: string;
  employeeId: number;
  location: string;
  licenseNumber: string;
  licenseExpiration: string;
  flyOut: string;
  isActive: boolean;
  itemCount: number;
  activeIndex: number;
  toggleEdit: () => void;
  navItems: NavItem[];
  employeeid: string;
  programArray: any[];
  // outlierCount: number;
}

const RightPopover = ({ closePopover, title, emailCompany, phone, name, employeeId, isActive, navItems, programArray }: RightPopoverProps) => {
  
  const [open, setOpen] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [mobileApprovalData, setMobileApprovalData] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeView, setActiveView] = useState(navItems[activeIndex].viewId);

  const [updateEmployee,] = useMutation(UPDATE_EMPLOYEE);
  const [updateProgram,] = useMutation(UPDATE_PROGRAM);

  const navigate = useNavigate();

  console.log("programArray", programArray);
  const badgeStatus = isActive ? 'fit' : 'unfit';

  const handleUpdate = async () => {
    if(!isApproved){try {
      // Determine the mutation variables based on whether it's the last item
      let mutationVariables = {
        id: employeeId,
        input: {
          ffdStatus: 'PENDING',
          //for now outlierCount remains at 1 -- check application logic for where this is used
          // outlierCount: 1,
          mobileApproval: {
            approved: true,
            timestamp: new Date().toISOString(),
            approvedData: activeView,  // This corresponds to the current tab
          },
        },
      };
      // Check if it's the last item in the navItems
      if (activeIndex >= navItems.length - 1) {
        // If it's the last item, set ffdStatus to ACTIVE
        mutationVariables.input.ffdStatus = 'ACTIVE';
        // mutationVariables.input.outlierCount = 0

        // Find the program with name "Mobile Driver Onboard" and set its status to INACTIVE
        const mobileDriverOnboardProgram = programArray.find(
          (program) => program.name === 'Mobile Driver Onboard'
        );
        console.log("mobileDriverOnboardProgram", mobileDriverOnboardProgram);
        if (mobileDriverOnboardProgram) {
          await updateProgram({
            variables: {
              id: mobileDriverOnboardProgram._id, // Use the program ID
              input: {
                status: 'INACTIVE',  // Set the status to INACTIVE
              },
            },
          });
        }
      }
      // Perform the mutation to update the employee
      await updateEmployee({
        variables: mutationVariables,
      });
      // If it's the last item, show the notification and close the popover after 5 seconds
      if (activeIndex >= navItems.length - 1) {
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
          closePopover();  // Close the popover after 5 seconds
          // Refresh the page after closing the popover
          window.location.reload();  // This will reload the page
        }, 5000); 
      } else {
        // If it's not the last item, just navigate to the next tab without showing notification
        navigateToNext();
      }
    } catch (err) {
      console.error('Error updating Employee:', err);
    }}else {
      // Navigate to the next tab without calling updateEmployee if already approved
      navigateToNext();
    }
  };

  const handleNameClick = () => {
    navigate(`/employeeDetail/${employeeId}`);
  };

  const handleClose = () => {
    setOpen(false);  // Close the popover and trigger the exit animation
    closePopover();  // If closePopover does extra cleanup, call it here
  };

  const navigateToNext = () => {
    if (activeIndex < navItems.length - 1) {
      const nextIndex = activeIndex + 1;
      setActiveIndex(nextIndex);
      setActiveView(navItems[nextIndex].viewId);  // Change the active view based on the new index
    }
  };

  // Callback to handle the mobileApproval data from the child
  const handleMobileApprovalData = (approvalData: any[]) => {
    setMobileApprovalData(approvalData);
  };

  // Re-run this effect whenever activeView or mobileApprovalData changes
  useEffect(() => {
    // Check if the current active view matches any approvedData in mobileApproval
    const isAlreadyApproved = mobileApprovalData.some(approval => approval.approvedData === activeView);

    setIsApproved(isAlreadyApproved);
  }, [activeView, mobileApprovalData]); 

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        {/* onClose used to be set to closePopover -- investigate which is the best approach */}
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-custom-color transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            {showNotification && <Notification />}
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-2xl">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="button-class absolute right-0 top-0 flex pr-3 pt-4 sm:pr-5">
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col bg-white shadow-xl">
                      <div className="">
                        <Dialog.Title className="text-1xl font-normal leading-6 text-gray-900 title-dark-gray" style={{ textTransform: 'uppercase' }}>
                          <div className="ml-1">
                            {title}
                          </div>
                        </Dialog.Title>
                      </div>
                      <div className="flex-1 overflow-y-auto p-4">
                        <div className="bg-white sm:rounded-lg">
                          <div className="px-4 py-2">
                            <div>
                              <dl>
                                <div className="bg-white px-4 py-2 sm:grid  sm:gap-4 sm:px-6">
                                  <h1 className="mt-1 text-2xl text-gray-900 mb-0 sm:col-span-2 flex items-center" onClick={handleNameClick}>
                                    <span className="underline-location">{name}</span>
                                    <div className="status mt-0 text-center text-sm font-medium">
                                      <span className={`status-indicator ${isActive ? 'status-active' : 'status-inactive'} custom-margin-left`}>
                                        <BadgeFlatWithDot
                                          title={<span>{isActive ? 'Ready for Review' : 'NOT COMPLETED'}</span>}  
                                          status={badgeStatus}
                                        />
                                      </span>
                                    </div>
                                  </h1>
                                </div>
                                <div className="bg-white px-2 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                                  <p className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{phone}</p>
                                </div>
                                <div className="bg-white px-4 py-1 sm:grid sm:grid-cols-3 sm:gap-2 sm:px-6">
                                  <h3 className="text-sm text-gray-500 mb-0 sm:mt-0 sm:col-span-2">{emailCompany}</h3>
                                </div>
                              </dl>
                            </div>
                          </div>
                          <SlideOverNav
                            activeView={activeView}
                            setActiveView={setActiveView}
                            navItems={navItems}
                            clickable={false}
                          />
                          {showNotification && <Notification />}
                          <div className="content-area">
                            {activeView === 'requiredInfo' && title === 'Mobile Driver Onboard' && (
                              <RequiredInfoComponent employeeid={employeeId} onMobileApprovalFetched={handleMobileApprovalData}/>
                            )}
                            {activeView === 'driver' && (
                              <DocumentDisplay employeeid={employeeId} type='driver'/>
                            )}
                            {activeView === 'history' &&(
                              <DocumentDisplay employeeid={employeeId} type='insurance'/>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="sticky bottom-0 bg-white p-4">
                        <hr className="border-t border-gray-400 mt-4 mb-4" />
                        <div className="action-buttons flex justify-end gap-3">
                          {/* <button className="cancel-next-btn text-xs text-black py-1 px-4">
                            CANCEL
                          </button> */}
                          <button onClick={handleUpdate} className="update-btn text-white py-1 px-4">
                          {isApproved ? "Already Approved" : "Approve?"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default RightPopover;