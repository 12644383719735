import { gql } from "@apollo/client";

const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: ProgramInput!) {
    updateProgram(id: $id, input: $input) {
      _id
    }
  }
`;

export { UPDATE_PROGRAM };