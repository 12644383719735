import { useState, useEffect } from 'react';
// import './requiredInfo.css';
import { useQuery } from '@apollo/client';
import GET_EMPLOYEE from '../../queries/getEmployee';
import DocumentUrlComponent from '../../helpers/fetchPreSignedURL';

interface DocumentDisplayProps {
    employeeid: number;
    type: string;
}

const DocumentDisplay = ({
  employeeid, 
  type
}: DocumentDisplayProps) => {

    const { loading: loadingEmployee, error: errorEmployee, data: dataEmployee, refetch } = useQuery(GET_EMPLOYEE, {
        variables: { id: employeeid },
    });

    const filteredDocuments = dataEmployee.getEmployeeById.documentReference.filter((doc: any) => {
        if (type === 'driver') {
          return doc.documentType === 'Driver\'s License';
        } else if (type === 'insurance') {
          return doc.documentType === 'Insurance';
        }
        return false;  // If type doesn't match, exclude document
      });
    
      const hasDocuments = filteredDocuments.length > 0;
        //i use this same function a second time in dashboard right slide over feed for drivers 
    //consider makine a helper function for this
    // const documentReferences = dataEmployee.getEmployeeById.documentReference.map((reference: any) => ({
    //     documentType: reference.documentType,
    //     documentId: reference.documentId,
    //     s3Bucket: reference.s3Bucket,
    //     s3Key: reference.s3Key,
    //     s3Url: reference.s3Url,
    // }));

    // const rowData = dataEmployee.getEmployeeById.documentReference.map((doc: any, index: any) => ({
    //       documentType: doc.documentType,
    //       presignedUrl: <DocumentUrlComponent s3Bucket={doc.s3Bucket} s3Key={doc.s3Key} display="large"/>
    //     }));
        
        // const hasDocuments = dataEmployee?.getEmployeeById?.documentReference?.length > 0;

        return (
          <div>
            {hasDocuments ? (
              filteredDocuments.map((doc: any, index: any) => (
                <div key={index}>
                  <p style={{ color: '#e32661', fontSize: '18px', textAlign: 'center', marginTop: '20px', marginBottom: '20px', textDecoration: 'none' }}>
                    {doc.documentType}
                  </p>
                  <DocumentUrlComponent s3Bucket={doc.s3Bucket} s3Key={doc.s3Key} display="large" />
                </div>
              ))
            ) : (
              <div style={{
                width: '300px',
                height: '200px',
                border: '2px dashed #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '20px auto', 
                borderRadius: '10px'
              }}>
                <span style={{ color: '#e32661', fontSize: '18px' }}>Documents</span>
              </div>
            )}
          </div>
        );
};

export default DocumentDisplay;
