import { gql } from '@apollo/client';

const GENERATE_PRESIGNED_URL = gql`
  query GeneratePresignedUrl($s3Bucket: String!, $s3Key: String!) {
    generatePresignedUrl(s3Bucket: $s3Bucket, s3Key: $s3Key) {
      s3URL
    }
  }
`;

export default GENERATE_PRESIGNED_URL;
export { GENERATE_PRESIGNED_URL };