import React, { useEffect, useState } from 'react';
import LeftNavBar from '../leftNavBar/index';
import LocationIcon from '../../assets/itw_locations_nav_bar_icon.svg';
import Drivers from '../../assets/employees-icon-white.svg';
import Dashboard from '../../assets/dashboardIconLeftNav.svg';
import Logo from "../logo";
import './mainTemplate.css';

import { useUser } from '../../context/userContext';


const MainTemplate = () => {
  
  const { userRole, userLocations } = useUser(); // Destructure userRole and userLocations from the context
  const [locationHref, setLocationHref] = useState('/location'); // Default location href

  console.log('userLocations', userLocations)

  //the below code is for use if displaying only one location for the user
  // useEffect(() => {
  //   // If the user is a Manager and has locations, set the href to the first location's detail page
  //   if (userRole === 'Manager' && userLocations && userLocations.length > 0) {
  //     setLocationHref(`/detailLocation/${userLocations[0]}`); // Use the first location ID
  //   }
  // }, [userRole, userLocations]);

  const navItems =[
    {
      href: "/dashboard",
      icon: Dashboard, 
      label: "Dashboard"
    }, 
    // {
    //   href: locationHref, // Dynamically set based on user role
    //   icon: LocationIcon, 
    //   label: "Locations"
    // }, 
    {
      href: "/location", // Dynamically set based on user role
      icon: LocationIcon, 
      label: "Locations"
    },
    {
      href: "/employeePage",
      icon: Drivers, 
      label: "Drivers"
    }, 
    // {
    //   href: "/vehiclepage",
    //   icon: TheCarIcon, 
    //   label: "Vehicles"
    // },
    // {
    //   href: "#",
    //   icon: Document, 
    //   label: "System Logs"
    // }, 
    // {
    //   href: "#",
    //   icon: Logout,  
    //   label: "Logout"
    // }, 
  ]
  
    return (
      <div>
        <LeftNavBar items={navItems}/>
        <div className="logo">
            <Logo />
        </div>
      </div>
    );
  };
  
  export default MainTemplate;