import { gql } from "@apollo/client";

const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: ID!, $input: LocationInput!) {
    updateLocation(id: $id, input: $input) {
      _id
    }
  }
`;

export { UPDATE_LOCATION };