import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import Webcam from 'react-webcam';
import Header from '../progressDots';
import './capture.css';
import { v4 as uuidv4 } from 'uuid';
import { gql, useMutation } from '@apollo/client';
import Resizer from 'react-image-file-resizer';
import { Circles } from 'react-loader-spinner';

import { UPDATE_EMPLOYEE } from '../../../queries/updateEmployee';
import { GENERATE_PRESIGNED_URL } from '../../../queries/generatePreSignedURL';
import { useUser } from '../../../context/userContext';



//beginning of solution for using computer vision to capture the sides of the image and automatically take a photo
// import cv from 'opencv.js'

// declare global {
//   interface Window {
//     cv: any;
//   }
// }

const UPLOAD_FILE_MUTATION = gql`
  mutation SingleUpload($file: Upload, $runTextract: Boolean!) {
    singleUpload(file: $file, runTextract: $runTextract) {
      filename
      mimetype
      encoding
      url
      extractedData {
        firstName
        lastName
        middleName
        suffix
        cityInAddress
        zipCodeInAddress
        stateInAddress
        stateName
        documentNumber
        expirationDate
        dateOfBirth
        dateOfIssue
        idType
        endorsements
        veteran
        restrictions
        class
        address
        county
        sex
      }
      vinData{
        vin
      }
      document{
        _id
        s3Reference{
          s3Bucket
          s3Key
          s3URL
        }
      }
    }
  }
`;

const DriverLicenseCapture = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState<null | string | undefined>(null);
  const { captureSide, nextCaptureSide, setCaptureSide, sidesCapturedStatus, setSidesCapturedStatus, uploadType, setUploadType, extractedData, setExtractedData, extractedVin, setExtractedVin  } = useUploadContext();
  const [orientation, setOrientation] = useState(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
  const [loading, setLoading] = useState(false);
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

  const userId = useUser();

  useEffect(() => {
    // Clear the captured image when the component mounts
    setImgSrc(null);
  }, [captureSide]);

  useEffect(() => {
    const handleResize = () => {
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //function to resize the image
  const resizeFile = (file: Blob): Promise<Blob> =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        800, // target width
        600, // target height
        'PNG', // output format
        80, // quality (0-100) - not used for PNG but required by the function
        0, // rotation
        (uri) => {
          resolve(uri as Blob);
        },
        'blob'
      );
    });
  
  const capture = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = (webcamRef.current as Webcam | null)?.getScreenshot({ width: 1920, height: 1080 });
      setImgSrc(imageSrc);
    }
  }, [webcamRef]);

  const retake = () => {
    setImgSrc(null);
  };
  
        const confirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          if (imgSrc) {
            setLoading(true);  // Set loading to true when uploading starts

            const blob = await fetch(imgSrc).then(res => res.blob());
            const resizedImage = await resizeFile(blob);
            const uniqueId = uuidv4();
            const fileName = uploadType === 'insuranceCard' ? `insurance_card_${uniqueId}_${captureSide}.png` : `driver_license_${uniqueId}_${captureSide}.png`;
            const file = new File([resizedImage], fileName, { type: 'image/png' });

            try {
              const runTextract = captureSide === 'front';
              const response = await uploadFile({ variables: { file, runTextract } })
              if (!response || !response.data || !response.data.singleUpload) {
                throw new Error('Failed to upload file or retrieve document details');
              }
              const document = response.data ? response.data.singleUpload.document : null;
              if (!document || !document._id) {
                throw new Error('Document creation failed. No document ID received.');
              }

      const newExtractedData = response.data ? response.data.singleUpload.extractedData : null;
      const newVinData = response.data ? response.data.singleUpload.vinData : null;
      const stringVinData = newVinData ? newVinData.vin : null;

      if (runTextract) {
        setExtractedData(newExtractedData);
        setExtractedVin(stringVinData);
      }
      console.log('Extracted Data:', newExtractedData);
      console.log('extratedData STATE', extractedData)

     // Immediately update the employee with the new document reference
     //right now we are updating the array with a new input instead of pushing a new object inside
      const updateResponse = await updateEmployee({
        variables: {
          id:userId.userId, 
          input: {
            //add date of birth, check this functionality 
            ...(newExtractedData?.dateOfBirth && { dob: newExtractedData.dateOfBirth }),
            documentReference: [
              { documentType: uploadType === 'driverLicense' ? 'Driver\'s License' : 'Insurance', 
                documentId: document._id, 
                s3Bucket:document.s3Reference.s3Bucket, 
                s3Key:document.s3Reference.s3Key, 
                s3Url:document.s3Reference.s3URL
              }
            ], 
            driversLicenseExpiration: extractedData.expirationDate,
            driversLicenseNum: extractedData.documentNumber,
          }
        }
      });

      if (!updateResponse || !updateResponse.data || !updateResponse.data.updateEmployee) {
        throw new Error('Failed to update employee with document reference.');
      }

              if (uploadType === 'driverLicense') {
                const newStatus = { ...sidesCapturedStatus, [captureSide]: true };
                setSidesCapturedStatus(newStatus);
                if (newStatus.front 
                  && newStatus.back
                ) 
                  {
                  navigate('/mobileDriverOnboard/profile', { state: { extractedData } });
                } 
                else {
                  setCaptureSide(nextCaptureSide);
                }
              } else if (uploadType === 'insuranceCard') {
                // setDocumentIdDLBack(document._id);
                const newStatus = { ...sidesCapturedStatus, [captureSide]: true };
                setSidesCapturedStatus(newStatus);

                if (newStatus.front && newStatus.back) {
                  navigate('/mobileDriverOnboard/personal-vehicle-info', { state: { extractedVin } });
                } else {
                  setCaptureSide(nextCaptureSide);
                }
              }
            } 
            catch (error) {
              console.error('Error uploading file:', error);
            }finally {
              setLoading(false);  // Set loading to false after uploading completes
            }
          }
        };
      
  const onCancel = () => {
    navigate('/mobileDriverOnboard/cancel');
  };

  const videoConstraints = {
    facingMode: "environment",
    width: orientation === 'landscape' ? { ideal: 2560 } : { ideal: 1440 },
    height: orientation === 'landscape' ? { ideal: 1440 } : { ideal: 2560 },
    aspectRatio: orientation === 'landscape' ? 16 / 9 : 9 / 16,
  };

  return (
    <div className="mobile-main-camera">
      <Header />
      <div className='mobile-body capture-body'>
        {loading && (
          <div className="loading-overlay">
            <Circles color="#007e9d" height={120} />
          </div>
        )}
        <h2 className='capture-h2'>Upload {uploadType === 'driverLicense' ? `Driver's License` : `Insurance`}</h2>
        <h2 className='capture-h2-side'>{captureSide === 'front' ? 'Front' : 'Back'}</h2>
        <div className="capture-area">
          {imgSrc ? (
            <img src={imgSrc} alt="Captured" />
          ) : (
            <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/png"
              screenshotQuality={1}
              forceScreenshotSourceSize={true} 
              style={{ width: '100%', height: '100%' }}
              videoConstraints={videoConstraints}
            />
             <div className="overlay">
                <div className="guidelines">
                  <div className="corner top-left"></div>
                  <div className="corner top-right"></div>
                  <div className="corner bottom-left"></div>
                  <div className="corner bottom-right"></div>
                </div>
              </div>
          </>
        )}
        </div>
        <div className="capture-button-container">
          {imgSrc ? (
            <>
              <button className="capture-button " onClick={confirm}>LOOKS GOOD</button>
              <button className="capture-cancel-button" onClick={retake}>RETAKE</button>
            </>
          ) : (
            <>
              <button className="capture-button" onClick={capture}>CAPTURE</button>
              <button className="capture-cancel-button" onClick={onCancel}>PAUSE</button>
            </>
          )}
       </div> 
      </div>
    </div>
  );
};

export default DriverLicenseCapture;
