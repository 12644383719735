import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import '../css/detailLocationPage.css';
import '../css/App.css'

import type { NavBarItem } from '../components/horizontalNavBar';

import HorizontalNavBar from '../components/horizontalNavBar';
import Table from '../components/table';
import PageTitle from '../components/pageTitle';
import PageBackButton from '../components/pageBackButton';
import MainTemplate from '../components/mainTemplate';
import InformationComponent from '../components/displayData';
import ProgramTertNav from '../components/programTertNav';
import { ReactComponent as LocationsIcon } from '../assets/itw_location_icon.svg';

import GET_LOCATION from '../queries/getLocation';
import { useQuery } from '@apollo/client';

import formatDate from '../helpers/formatDate';
import formatNumber from '../helpers/formatNumber';

import { useUser } from '../context/userContext';

const DetailLocation = () => {
  const [currentView, setCurrentView] = useState('INFORMATION');

  const navigate = useNavigate();
  const { id } = useParams();

  const {userRole} = useUser();

  const { loading, error, data, refetch } = useQuery(GET_LOCATION, {
    variables: { id }
  });

  const locationData = data?.getLocation;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  //here we have changed mvrScore to onboardStatus -- make sure the shape of the data matched this in the back and front --return a boolean or string?  
  interface IDriver {
    driverName: string;
    location: string;
    phone: string;
    emailCompany: string;
    driversLicense: string;
    dlExpiry: string;
    mvrScore: string;
    // onboardStatus: string;
    _id: string; 
  }

  const handleNavItemClick = (view: string, event: React.MouseEvent) => {
    event.preventDefault();
    setCurrentView(view);
  };

  const handleBackButtonClick = () => {
    navigate("/location");
  };

  const handleDriverRowClick = (driverId: string) => {
    if (driverId) {
      console.log("Navigating to driver with ID:", driverId);
      navigate(`/employeedetail/${driverId}`);
    } else {
      console.error("Driver ID is undefined");
    }
  };

  const handlePageTitleClick = () => {
    setCurrentView('INFORMATION');
    refetch();
  };

  const onUpdateInformation = () => {
    console.log('Information updated');
  }

  //establish a process to associate driver with location--
    //on employee creation, update the location array with the id of the employee 
  const driversData: IDriver[] = locationData.employees.map((employee: any) => ({
    driverName: `${employee.firstName} ${employee.lastName}`,
    location: employee.location,
    phone: employee.phone,
    emailCompany: employee.emailCompany,
    driversLicense: employee.driversLicenseNum,
    dlExpiry: formatDate(employee.driversLicenseExpiration),
    mvrScore: employee.mvrScore,
    _id: employee._id
  }));

  const columnDefsDrivers = [
    { field: 'driverName', headerName: 'NAME', flex: 1, cellRenderer: (params: any) => {
        return (
            <button className='underline-location' onClick={() => handleDriverRowClick(params.data._id)}>{params.value}</button>
        );
      }
    },
    { field: 'phone', headerName: 'PHONE', flex: 1 },
    { field: 'emailCompany', headerName: 'EMAIL', flex: 1 },
    { field: 'driversLicense', headerName: 'DRIVERS LICENSE', flex: 1 },
    { field: 'dlExpiry', headerName: 'DL EXPIRY', flex: 1 },
    { field: 'mvrScore', headerName: 'ONBOARD STATUS', flex: 1 },
  ];

  const navBarItems: NavBarItem[] = [
    { label: 'INFORMATION', href: `/detailLocation/${id}`, onClick: (event) => handleNavItemClick('INFORMATION', event) },
    { label: 'DRIVERS', href: `/detailLocation/${id}`, onClick: (event) => handleNavItemClick('DRIVERS', event) },
  ];
      // Conditionally add the PROGRAMS item if the user is not a Manager
    if (userRole !== 'Manager') {
      navBarItems.push({ 
        label: 'PROGRAMS', 
        href: `/detailLocation/${id}`, 
        onClick: (event) => handleNavItemClick('PROGRAMS', event) 
      });
    }

  const detailData = {
    sections: [
      {
        title: 'Basic Information',
        isActive: currentView === 'INFORMATION',
        data: [
          { label: 'Location ID', value: locationData.locationId },
          { label: 'Building ID', value: locationData.buildingId },
          { label: 'Operation Type', value: locationData.operationType },
          { label: 'Number of Full Time Employees', value: formatNumber(locationData.numFtEmployees) },
          { label: 'Number of Part Time Employees', value: formatNumber(locationData.numPtEmployees) },
          { label: 'Address', value: `${locationData.addressStreet}, ${locationData.addressCity}, ${locationData.addressState} ${locationData.addressZip}` },
          { label: 'Hours of Operation', value: locationData.hoursOfOperation },
          { label: 'Property Info', value: locationData.ownedOrLeased },
          { label: 'Owner Name', value: locationData.ownerName },
          { label: 'Owner Contact', value: locationData.ownerEmail },
          { label: '', value: locationData.ownerPhone },
          { label: 'Lease Expiration', value: formatDate(locationData.leaseExpirationDate) },
          { label: 'Building Square Footage', value: formatNumber(locationData.buildingSqFt) },
          { label: 'Active Status', value: locationData.isActive },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Licensing Information',
        isActive: currentView === 'Licensing Information',
        data: [
          { label: 'Licensed Entity Name', value: locationData.licensedEntityName },
          { label: 'Licensed DBA', value: locationData.licensedDBA },
          { label: 'License Location Address', value: locationData.licenseLocationAddress },
          { label: '', value: locationData.licenseState },
          { label: 'License Type', value: locationData.licenseType },
          { label: 'License or Permit Number', value: locationData.licenseOrPermitNumber },
          { label: 'License Regulator Number', value: locationData.licenseRegulator },
          { label: 'License Effective Date', value: formatDate(locationData.licenseEffectiveDate) },
          { label: 'License Expiration Date', value: formatDate(locationData.licenseExpirationDate) },
          { label: 'License Cost', value: locationData.licenseCost },
          { label: 'License Status', value: locationData.licenseStatus },
          { label: 'Type of Operation Conducted', value: locationData.typeOfOperationsConducted },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Compliance and Regulatory Metrics',
        isActive: currentView === 'Compliance and Regulatory Metrics',
        data: [
          { label: 'Compliance Violations', value: locationData.complianceViolations },
          { label: 'Licensed Renewal Success Rate', value: locationData.licenseRenewalSuccessRate },
          { label: 'Audit Findings', value: locationData.auditFindings },
          { label: 'Regulatory Fines Penalties', value: locationData.regulatoryFinesPenalties },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Property Information',
        isActive: currentView === 'Property Information',
        data: [
          { label: 'Owned or Leased', value: locationData.ownedOrLeased },
          { label: 'Owner Name', value: locationData.ownerName },
          { label: 'Owner Contact Information', value: locationData.ownerEmail },
          { label: '', value: locationData.ownerPhone },
          { label: 'Building Square Foot', value: formatNumber(locationData.buildingSqFt) },
          { label: 'Lease Expiration Date', value: formatDate(locationData.leaseExpirationDate) },
          { label: 'Total Insurance Value', value: locationData.totalInsuranceValue },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Operational Efficiency',
        isActive: currentView === 'Operational Efficiency',
        data: [
          { label: 'Cost of Goods Sold (COGS)', value: locationData.costOfGoodsSold },
          { label: 'Gross Profit Margin', value: locationData.grossProfitMargin },
          { label: 'Operating Expenses Ratio', value: locationData.operatingExpenseRatio },
          { label: 'Inventory Turnover Rate', value: locationData.inventoryTurnoverRate },
          { label: 'Production Efficiency (FOR CULTIVATION FACILITIES)', value: locationData.productionEfficiency },
          { label: 'Fulfillment and Distribution Efficiency', value: locationData.fulfillmentDistributionEfficiency },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Health and Safety Metrics',
        isActive: currentView === 'Health and Safety Metrics',
        data: [
          { label: 'Incident Rate', value: locationData.incidentRate },
          { label: 'Compliance with Safety Regulations', value: locationData.complianceWithSafetyRegulations },
          { label: 'Health and Safety Training Completion Rate', value: locationData.healthSafetyTrainCompleteRate },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Social Responsibility',
        isActive: currentView === 'Social Responsibility',
        data: [
          { label: 'Community Impact Metrics', value: locationData.communityImpactMetrics },
          { label: 'Sustainability Initiatives', value: locationData.sustainabilityInitiatives },
          { label: 'Diversity and Inclusion Metrics', value: locationData.diversityInclusionMetrics },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Manager On Site',
        isActive: currentView === 'Manager On Site',
        data: [
          { label: 'Name', value: locationData.locManagerName },
          { label: 'Contact Info', value: locationData.locManagerEmail },
          { label: '', value: locationData.locManagerPhone },
        ].filter(item => Boolean(item.value)),
      },
      {
        title: 'Secondary Contact Info',
        isActive: currentView === 'Secondary Contact Info',
        data: [
          { label: 'Name', value: locationData.secondContactName },
          { label: 'Contact Info', value: locationData.secondContactEmail },
          { label: '', value: locationData.secondContactTitle },
        ].filter(item => Boolean(item.value)),
      },
    ].filter(section => section.data.length > 0),
  };

  return (
      <div >
        <MainTemplate />
        <div style={{height:"100%"}} className="main-container">
          <div className="header">
            {/* Render PageBackButton only if the user is not a Manager */}
            {userRole !== 'Manager' &&
          <PageBackButton onClick={handleBackButtonClick} name="Locations" />}
          <div className="title-icons-container">
          <span className="locations-icon">
            <LocationsIcon />
          </span>
            <button className="page-title-button" onClick={handlePageTitleClick}>
              <PageTitle pageTitle={locationData.name || 'Loading Location...'} />
            </button>
          </div>
          </div>
          <div className="horizontal-nav">
            <div style={{position:'absolute', right:'1rem', width: 'calc(100% + 1rem)', left: '-1rem'}}>
            
              <HorizontalNavBar items={navBarItems} />
            </div>
            
          {currentView === 'INFORMATION'}
          <div className="info-container">
            {(() => {
              switch (currentView) {
                case 'INFORMATION':
                  return <InformationComponent sections={detailData.sections} employeeId={'67002cc8e40b9193d2281fa8'} onUpdateInformation={onUpdateInformation}/>;
                case 'DRIVERS':
                  return (
                      <div className="table-filter-detail-locations mt-4" style={{height:'100%'}}>
                        <Table columnDefs={columnDefsDrivers} rowData={driversData} onRowClick={(event: any) => handleDriverRowClick(event.data._id)} />
                      </div>
                  );
                case 'PROGRAMS':
                  return (
                      <ProgramTertNav
                          activeView={currentView}
                          setActiveView={setCurrentView}
                          navItems={[
                            { label: 'Driver Onboarding', viewId: 'employeeOnboarding' }
                          ]}
                      />
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
        </div>
      </div>
  );
};
export default DetailLocation;
