import { useState, ChangeEvent, FormEvent } from "react";
import {UPDATE_USER} from '../queries/updateUser';
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import CreateAccountComponent from "../components/createAccount";
import Notification from "../components/notification";
import { useLazyQuery } from "@apollo/client";
import DECODE_TOKEN from "../queries/decodeToken";
import { useEffect } from "react";
// import GET_USER from "../queries/getUser";
import {VERIFY_OTP} from "../queries/verifyOtp";


function CreateAccount() {
  // State for email and password
  const [password, setPassword] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [decodedUserId, setDecodedUserId] = useState<string | null>(null);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [error, setError] = useState('');  
  
  //hardcoded for now -- when otp and email is implemented, these will be accessible thru tokens
  //note : can i use the same functionality we have set up for mobile driver onboarding email?  
  // const userId= decodedUserId
  const role = 'Manager'

  const [updateUser] = useMutation(UPDATE_USER);
  const navigate = useNavigate();


  const [decodeToken, { data: decodeData }] = useLazyQuery(DECODE_TOKEN)
  const [verifyOTP] = useLazyQuery (VERIFY_OTP, {
    onCompleted: (data) => {
      if (data.verifyOtp.success) {
        setOtpVerified(true); // Mark OTP as verified
        setError(""); // Clear any errors
      } else {
        setError("Invalid OTP, please try again.");
      }
    },
    onError: (error) => {
      setError("Failed to verify OTP. Please try again.");
    }
  });

    // // Handle OTP input changes
    // const handleOtpChange = (event: ChangeEvent<HTMLInputElement>) => {
    //   setOtp(event.target.value);
    // };

  // Function to handle changes in the password input field
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  
  console.log('decodeData:', decodeData)
  //function to get the token from the URI 

  const getTokenFromUri = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  }

  useEffect(() => {
    const token = getTokenFromUri();
    if (token) {
      decodeToken({ variables: { token } });
    }
  }, [decodeToken]);

  useEffect(() => {
    if (decodeData && decodeData.decodeToken && decodeData.decodeToken.userId) {
      setDecodedUserId(decodeData.decodeToken.userId);
      console.log('decodedUserId:', decodedUserId);
    }
  }, [decodeData]);


  // Function to handle OTP submission
  const handleOtpSubmit = async (otp: string) => {
    // setLoadingOtpVerification(true);
    // setError(''); // Clear any previous error

    try {
      //specify user or manager, this distinction will translate to a different repository called on server
      const entityType ="user"
      // Make a request to verify the OTP
      await verifyOTP({ variables: { entityId: decodedUserId, enteredOtp: otp, entityType } });
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Error verifying OTP, please try again.');
      // setLoadingOtpVerification(false);
    }
  };
console.log('error:', error)  
// Function to handle form submission
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    console.log("Form submitted with password:", password);

    try {
      // Call the updateUser mutation and pass the new password
      const { data } = await updateUser({
        variables: {
          id: decodedUserId, 
          input: {
            _id: decodedUserId,
            password: password, 
            role: role
          },
        },
      });

            // Show notification overlay
            setShowNotification(true);
            // After 10 seconds, hide the notification and navigate to login page
            setTimeout(() => {
              setShowNotification(false);
              navigate('/');  // Redirect to login page
            }, 2500);  // 5 seconds
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <>
    {showNotification && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: '#ffb417',
          color: 'black',
          padding: '10px',
          textAlign: 'center',
          zIndex: 1000,
        }}>
          Password updated successfully! You will be redirected to login.
        </div>
      )}
      <div 
      style={{ filter: showNotification ? 'blur(5px)' : 'none', transition: 'filter 0.3s ease' }}
      >
        <CreateAccountComponent 
          password={password}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
          handleOtpSubmit={handleOtpSubmit}
          otpVerified={otpVerified} 
          error={error}
          // otp={otp}        
          />
      </div>
    </>
  );
}

export default CreateAccount;
