import { gql } from '@apollo/client';

const DECODE_TOKEN = gql`
  query DecodeToken($token: String!) {
    decodeToken(token: $token) {
      userId
      email
    }
  }
`;

export default DECODE_TOKEN;
