import { Routes, Route } from 'react-router-dom';
import { UploadProvider } from '../context/uploadProvider';
import MobileAuthenticate from '../components/mobileDriverOnboard/authenticate';
import TermsConditions from '../components/mobileDriverOnboard/termsConditions';
import DriverLicenseUpload from '../components/mobileDriverOnboard/upload';
import DriverLicenseCapture from '../components/mobileDriverOnboard/capture';
import Profile from '../components/mobileDriverOnboard/driverProfile';
import PersonalVehicle from '../components/mobileDriverOnboard/personalVehicle';
import PersonalVehicleInformation from '../components/mobileDriverOnboard/personalVehicleInformation';
import VehicleInfo from '../components/mobileDriverOnboard/decodeVin';
import Cancel from '../components/mobileDriverOnboard/cancel';
import Confirmation from '../components/mobileDriverOnboard/confirmation';
import '../css/mobileDriverOnboard.css'; 
import ProtectedRoute from '../hoc/ProtectedRoute/ProtectedRoute';

function MobileDriverOnboard(): JSX.Element {
  return (
    <> 
      <div className='mobile-container'>
        <UploadProvider>
          <Routes>
            <Route path="authenticate" element={<MobileAuthenticate />} />
            <Route path="terms-conditions" element={<ProtectedRoute><TermsConditions /></ProtectedRoute>} />
            <Route path="upload" element={<ProtectedRoute><DriverLicenseUpload /></ProtectedRoute>} />
            <Route path="capture" element={<ProtectedRoute><DriverLicenseCapture /></ProtectedRoute>}/>
            <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="personal-vehicle" element={<ProtectedRoute><PersonalVehicle /></ProtectedRoute>} />
            <Route path="personal-vehicle-info" element={<ProtectedRoute><PersonalVehicleInformation /></ProtectedRoute>} />
            <Route path="confirmation" element={<ProtectedRoute><Confirmation /></ProtectedRoute>} />
            <Route path="/" element={<MobileAuthenticate />} /> {/* Default Route */}
            <Route path="cancel" element={<ProtectedRoute><Cancel /></ProtectedRoute>} />
          </Routes>
        </UploadProvider>
      </div>
    </>
  );
}

export default MobileDriverOnboard;
