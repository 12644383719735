import { gql } from '@apollo/client';

const GET_ALL_EMPLOYEES = gql`
  query GetAllEmployees($filter: EmployeeFilter) {
    getAllEmployees(filter: $filter) { 
        _id
        location
        userId
        firstName
        middleName
        lastName
        addressStreet
        addressCity
        addressState
        addressZip
        phone 
        outlierCount
        emailCompany
        driversLicenseNum
        driversLicenseExpiration
        relationshipToEmployee
        ffdStatus
        startDate
        termsAgreement{
          timestamp
        }
        locationReference{
            locManagerName
            name
        }
       }
    }`

export default GET_ALL_EMPLOYEES;
export { GET_ALL_EMPLOYEES };