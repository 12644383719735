// Email.js
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import ADD_EMPLOYEE from '../../queries/createEmployeeByEmail';
import ADD_PROGRAM from '../../queries/addProgram';
import { useUser } from '../../context/userContext';

export default function Email() {
    const [emailPersonal, setEmailPersonal] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState(''); 
    const [addEmployee, { data, loading, error }] = useMutation(ADD_EMPLOYEE);
    const [addProgram, {data:programData, loading:programLoading, error:programError}] = useMutation(ADD_PROGRAM);
    const { userId } = useUser();
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Hardcoded location reference for development
        // Clarify with Product : can admin add driver to any location, or just managers?
        // what is the process for adding a driver?  click on location first?  
        const locationReference = '65eb43d44f41993c85d658fd'
        try {
            // Try to add the program first
            const { data: programData } = await addProgram({
              variables: {
                input: {
                  name: 'Mobile Driver Onboard',
                  status: 'ACTIVE',
                  operator: 'Trulieve',
                }
              }
            });
          
            if (programData) {
              const programId = programData.addProgram._id;  // Ensure this matches the field name in your GraphQL schema
              console.log("Program ID:", programId);
              console.log('Program added successfully:', programData.addProgram);
          
              try {
                // Now try to add the employee with the programId
                const { data: employeeData } = await addEmployee({
                  variables: {
                    input: {
                      emailPersonal,
                      emailCompany: emailPersonal,
                      locationReference, // Assume this comes from your session or some other source
                      firstName,
                      lastName,
                      program: [programId],  // Ensure this is an array as required
                    }
                  }
                });
          
                console.log('Employee added successfully:', employeeData.addEmployee);
          
              } catch (employeeError) {
                console.error('Error adding employee:', employeeError);
              }
            }
          } catch (programError) {
            console.error('Error adding program:', programError);
          }
          
        
        // try {
        //     await addEmployee({ variables: 
        //         { input: 
        //             { 
        //                 emailPersonal, 
        //                 emailCompany: emailPersonal,
        //                 // location: this will need to be stored in sessions,
        //                 locationReference, // not sure where id is coming from-check createEmployeeByEmail.tsx for structure
        //                 firstName, 
        //                 lastName, 
        //                 // first, create the mutation to make the program.  once the response is successful, then add employee
        //                 program :{
        //                     name: 'Mobile Driver Onboard',
        //                     status: 'Active',
        //                     operator: 'Trulieve',
        //                     // createdBy: {userId}, uncomment when userId is available
        //                     criteria: [
        //                         {name: 'driver\'s license expiration'},
        //                         {name: 'insurance expiration'}
        //                     ]
        //                 } 
        //             }
        //         } 
        //     });
        //     console.log('Employee added successfully:', data);
        // } catch (e) {
        //     console.error('Error adding employee:', e);
        // }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
            <div>
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                    First Name
                </label>
                <div className="mt-2">
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div className="mt-4">
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                    Last Name
                </label>
                <div className="mt-2">
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last Name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    Email
                </label>
                <div className="mt-2">
                    <input
                        id="emailPersonal"
                        name="emailPersonal"
                        type="email"
                        value={emailPersonal}
                        onChange={(e) => setEmailPersonal(e.target.value)}
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <div className="mt-4">
                <label htmlFor="role" className="block text-sm font-medium leading-6 text-gray-900">
                    Select Role
                </label>
                <div className="mt-2">
                    <select
                        id="role"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    >
                        <option value="">Select a role</option>
                        <option value="Manager">Manager</option>
                        <option value="Driver">Driver</option>
                        <option value="Admin">Admin</option>
                    </select>
                </div>
            </div>
            <button type="submit" className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded">
                Add Employee
            </button>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error.message}</p>}
            {data && (
                <div>
                    <p>Employee added successfully!</p>
                    <p>ID: {data.addEmployee._id}</p>
                    <p>Email: {data.addEmployee.emailPersonal}</p>
                </div>
            )}
        </form>
    );
}
