import { gql } from '@apollo/client';

const ADD_VEHICLE = gql`
  mutation addVehicle ($input: VehicleInput!){
    addVehicle (input: $input){
        _id
    }
  }
`;

export default ADD_VEHICLE;
export { ADD_VEHICLE };