import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import './termsConditions.css'; 
import LogoMobile from "../../logoMobile";
import { gql } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useUser } from '../../../context/userContext';

const UPDATE_EMPLOYEE= gql`
  mutation UpdateEmployee($id: ID!, $input: EmployeeInput!) {
    updateEmployee(id: $id, input: $input) {
      _id
    }
  }`

function TermsConditions(): JSX.Element {
  const { userId } = useUser();
  console.log("userid",userId);
  const navigate = useNavigate();
  //sets the context for uploadType to driversLicense.  this ensures UI will prompt user to capture DL
  //also for AWS Textract to return the correct result  
  const { setUploadType } = useUploadContext();
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);


  const handleAgree = async () => {
    // Call the mutation to update the employee with "agreed" as true
    try {
      await updateEmployee({
        variables: {
           id:userId,
          input: {
            termsAgreement: {
              agreed: true,
              timestamp: new Date().toISOString(),
            },
          },
        },
      });
      // After successful mutation, navigate to the next step
      setUploadType('driverLicense');
      navigate('/mobileDriverOnboard/upload');
    } catch (error) {
      console.error("Error updating terms agreement: ", error);
    }
  };

  const handleDecline = async () => {
    // Call the mutation to update the employee with "agreed" as false
    try {
      await updateEmployee({
        variables: {
          id:userId,
          input: {
            termsAgreement: {
              agreed: false,
              timestamp: new Date().toISOString(),
            },
          },
        },
      });
      // After successful mutation, navigate to page that states :
      // "We're sorry, but you must agree to the terms and conditions to continue."
      // currently navigates to home page, which will not render because of permissions
      navigate('/mobileDriverOnboard/');
    } catch (error) {
      console.error("Error updating terms agreement: ", error);
    }
  };

  return (
    <>
    <div className="mobile-main terms-conditions-container">
      <div className='mobile-header-goat'>
        <LogoMobile />
      </div>
        <div className = "mobile-body terms-conditions-body">
          <div className="term-conditions-header-container"> 
            {/* <button className="mobile-back-button" onClick={() => window.history.back()}>←</button> */}
          <h1 className="terms-h1">In the Weeds</h1>
          </div>
          <h2 className="terms-h2">User Agreement</h2>        
          <div className="terms-text">
            <h3>A. User Terms and Agreement</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
            <h3>B. Morbi placerat felis ultricies lacus sed turpis tincidunt.</h3>
            <p>Phasellus id velit vel eros volutpat adipiscing. Phasellus in erat ut odio suscipit sollicitudin. Phasellus tincidunt purus nec diam elementum, non congue elit pulvinar.</p>
            <h3>C. Lacus sed viverra</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
          </div>
        </div>         
        <div className="terms-button-container">
          <button className="terms-accept-button" onClick={handleAgree}>I AGREE</button>
          <button className="terms-back-button" onClick={handleDecline}>DECLINE</button>
        </div> 
    </div>
    </>
  );
}

export default TermsConditions;
