// queries/updateUser.ts
import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      _id
      email
      role
    }
  }
`;
