import './confirmation.css';
import LogoMobile from '../../logoMobile';
import { UPDATE_EMPLOYEE } from '../../../queries/updateEmployee';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useUser } from '../../../context/userContext';


const Confirmation = () => {
    const [updateEmployee, { data, loading, error }] = useMutation(UPDATE_EMPLOYEE);
    const { userId } = useUser();
    useEffect(()=>{
        const updateEmployeeStatus = async () => {
            try{
                await updateEmployee({
                    variables :{
                        id: userId,
                        input: {
                            ffdStatus: 'PENDING', 
                            outlierCount:1
                        }
                    }
                })
            }catch{

            }
        }; 
        updateEmployeeStatus();
    }, [updateEmployee, userId])
    return (
        <div className="mobile-main">
            <div className="mobile-header-goat confirmation-header">
                <LogoMobile />
            </div>
            <div className="mobile-body ">
                <p className="confirmation-message">
                    Thank you for completing your onboarding questionaire!
                </p>
                <p className="confirmation-submessage">
                    
                </p>
                {/* <button className="confirmation-button">
                    CLOSE
                </button> */}
            </div>  
        </div>
    );
};

export default Confirmation;
