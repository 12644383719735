import { gql } from '@apollo/client';

export const GET_LOGS_BY_EMPLOYEE_ID = gql`
  query GetLogsByEmployeeId($employeeId: ID!) {
    getLogsByEmployeeId(employeeId: $employeeId) {
      _id
      timestamp
      action
      status
      description
      employeeId
      user {
        _id
      }
    }
  }
`;
