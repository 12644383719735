import { useState, useEffect } from 'react';
import './personalVehicleInformation.css';  // Import the component-specific CSS file
import Header from '../progressDots';
import VehicleInfo from '../decodeVin';  
import { useNavigate } from 'react-router-dom';
import { useUploadContext } from '../../../context/uploadProvider';
import YearPicker from '../yearPicker'
import { ADD_VEHICLE } from '../../../queries/addVehicle';
import { UPDATE_EMPLOYEE } from '../../../queries/updateEmployee';
import { useMutation } from '@apollo/client';
import { useUser } from '../../../context/userContext';

function PersonalVehicleInformation(): JSX.Element {
  const navigate = useNavigate();
  const { setUploadType, setCaptureSide, setNextCaptureSide, extractedVin } = useUploadContext();

  const [make, setMake] = useState('');
  const [year, setYear] = useState<number | null>(null);
  const [yearError, setYearError] = useState<string>('');
  const [makeError, setMakeError] = useState('');
  const [model, setModel] = useState('');
  const [modelError, setModelError] = useState('');
  const [series, setSeries] = useState('');
  const [seriesError, setSeriesError] = useState('');
  const [vin, setVin] = useState(extractedVin || '' );
  const [vinError, setVinError] = useState('');
  const [vehicleData, setVehicleData] = useState(null);
  const [submitted, setSubmitted] = useState(false); // State to track form submission

  const [addVehicle] = useMutation(ADD_VEHICLE);
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

  // notes in database that this vehicle is employee Owned
  const personalVehicle = true;

  //collecting userId (to reference object id in database) from context  
  const { userId } = useUser();
  console.log("userid",userId);

  useEffect(() => {
    if (extractedVin) {
      setVin(extractedVin);
    }
  }, [extractedVin]);

  const validateMake = (make: string) => {
    if (!make) {
      setMakeError('Make cannot be empty.');
      return false;
    }
    setMakeError('');
    return true;
  };

  const validateModel = (model: string) => {
    if (!model) {
      setModelError('Model cannot be empty.');
      return false;
    }
    setModelError('');
    return true;
  };

  const validateSeries = (series: string) => {
    if (!series) {
      setSeriesError('Series cannot be empty.');
      return false;
    }
    setSeriesError('');
    return true;
  };

  const validateVin = (vin: string) => {
    if (vin.length !== 17) {
      setVinError('VIN must be 17 characters long.');
      return false;
    }
    setVinError('');
    return true;
  };

  const handleYearChange = (newYear: number) => {
    setYear(newYear);
    if (submitted) {
      validateYear(newYear);
    }
  };

  const validateYear = (year: number | null) => {
    if (year === null || year < 1980 || year > new Date().getFullYear()) {
      setYearError('Please enter a valid year after 1980.');
      return false;
    }
    setYearError('');
    return true;
  };

  const handleMakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMake(e.target.value);
    if (submitted) {
      validateMake(e.target.value);
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModel(e.target.value);
    if (submitted) {
      validateModel(e.target.value);
    }
  };

  const handleSeriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeries(e.target.value);
    if (submitted) {
      validateSeries(e.target.value);
    }
  };

  const handleVinChange = (vin: any) => {
    setVin(vin);
    if (submitted) {
      validateVin(vin);
    }
  };

  const onContinue = async () => {
    setSubmitted(true); // Set submitted to true when continue is clicked
    const isYearValid = validateYear(year);
    const isMakeValid = validateMake(make);
    const isModelValid = validateModel(model);
    const isSeriesValid = validateSeries(series);
    const isVinValid = validateVin(vin);

    // if (isYearValid && isVinValid && isMakeValid && isModelValid && isSeriesValid) {
    //   try {
    //     const { data } = await addVehicle({
    //       variables: {
    //         input: {
    //           vin,
    //           year,
    //           make,
    //           model,
    //           series,
    //           personalVehicle, 
    //           employeeReference:userId
    //           //add a document reference here to store reference to the insurance card, right now it is in documentContext
    //         },
    //       },
    //     });
    //     console.log('Vehicle added:', data);
    //     navigate('/mobileDriverOnboard/confirmation'); // Navigate after successful mutation
    //   } catch (error) {
    //     console.error('Error adding vehicle:', error);
    //   }
    // }
    if (isYearValid && isVinValid && isMakeValid && isModelValid && isSeriesValid) {
      try {
        // Step 1: Add the vehicle
        const { data } = await addVehicle({
          variables: {
            input: {
              vin,
              year,
              make,
              model,
              series,
              personalVehicle,
              employeeReference: userId,
            },
          },
        });
  
        const vehicleId = data?.addVehicle?._id; // Capture the returned vehicleId
  
        if (!vehicleId) {
          throw new Error('Vehicle ID is missing.');
        }
  
        // Step 2: Update the employee document with the new vehicleReference
        const updateEmployeeResponse = await updateEmployee({
          variables: {
            id: userId,
            input: {
              vehicleReference: [
                vehicleId, // Add the new vehicle ID here
              ],
            },
          },
        });
  
        console.log('Employee updated with vehicle:', updateEmployeeResponse);
        navigate('/mobileDriverOnboard/confirmation'); // Navigate after successful mutation
      } catch (error) {
        console.error('Error adding vehicle and updating employee:', error);
      }
    }
  };

  const onBack = () => {
    navigate('/mobileDriverOnboard/personal-vehicle');
  };

  const handleVehicleDataFetched = (data: any) => {
    setVehicleData(data);
    if (data) {
      setYear(data.ModelYear);
      setMake(data.Make);
      setModel(data.Model);
      setSeries(data.BodyClass);
    }
  };

  return (
    <div className='mobile-main vehicle-info-setup'>
      <Header />
      <div className="mobile-body personal-vehicle-info-body">
        <div className="your-vehicle-info">
          <h2 className="vehicle-info-info-h2">Your Vehicle Info</h2>
          <h3 className="vehicle-info-info-h3">VIN</h3>
          <p>You can find it on the driver's side dashboard, door frame, or in your car's insurance and registration documents.</p>
          <VehicleInfo onVehicleDataFetched={handleVehicleDataFetched} vin={vin} onVinChange={handleVinChange}/>
          {submitted && vinError && <div className="personal-vehicle-info-error-message">{vinError}</div>}
          <h3 className="vehicle-info-info-h3-or">
            <span>OR</span>
          </h3>
          <h3 className="vehicle-info-info-h3"> ADD YOUR DETAILS </h3>
          <div className="vehicle-info-form-container">
            <div className="vehicle-info-form-group">
              <div style={{width:'100%'}}>
                <YearPicker
                  year={year}
                  setYear={setYear}
                />
              </div>
              {submitted && yearError && <div className="personal-vehicle-info-error-message">{yearError}</div>}
              <input 
                type="text" 
                placeholder="Make" 
                value={make}
                onChange={handleMakeChange}
              />
              {submitted && makeError && <div className="personal-vehicle-info-error-message">{makeError}</div>}
              <input 
                type="text" 
                placeholder="Model" 
                value={model}
                onChange={handleModelChange}
              />
              {submitted && modelError && <div className="personal-vehicle-info-error-message">{modelError}</div>}
              <input 
                type="text" 
                placeholder="Series" 
                value={series}
                onChange={handleSeriesChange}
              />
              {submitted && seriesError && <div className="personal-vehicle-info-error-message">{seriesError}</div>}
            </div>
          </div>
        </div>
        <div className="vehicle-info-button-container">
          <button className="vehicle-info-continue-button" onClick={onContinue}>CONTINUE</button>
          <button className="vehicle-info-back-button" onClick={onBack}>BACK</button>
        </div>
      </div>
    </div>
  );
}

export default PersonalVehicleInformation;
