import { Fragment, ReactNode, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import '../../tailwind.css';
import './filterDropdown.css';
import { set } from 'date-fns';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface MenuItem {
  label: string;
  href: string;
}

interface FilterDropdownProps {
  buttonTitle: string;
  menuItems: MenuItem[];
  onItemSelected?: (selectedItem: string) => void;
  onFilterReset?: () => void; // New prop for resetting the filter
}

const FilterDropdown = ({ buttonTitle, menuItems, onItemSelected, onFilterReset}: FilterDropdownProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [isOpen, setIsOpen]=useState(false);

  const handleMenuItemClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemLabel: string) => {
    event.preventDefault();

    setSelectedFilter(itemLabel); // Set the selected filter

    if (onItemSelected) {
      onItemSelected(itemLabel); // Trigger the filter selection callback
    }
    setIsOpen(false);
  };

  const handleRemoveFilter = () => {
    setSelectedFilter(null); // Clear the selected filter
    if (onFilterReset) {
      console.log('Filter reset, triggering callback');
      onFilterReset(); // Trigger the filter reset callback
    }
  };

  return (
    <div className="relative inline-block text-left">
      {/* Button and active filter display */}
      <div className="flex items-center space-x-2">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => setIsOpen(!isOpen)}
            >
              {buttonTitle}
              <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={isOpen}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              style={{ minWidth: '200px', width: 'auto', right: '0', zIndex: 50 }}
              className="dropdown-menu absolute right-0 z-10 mt-2 w-56 min-w-max origin-top-right rounded-md bg-white shadow-lg border border-gray-300 ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                {menuItems.map((item, index) => (
                  <Menu.Item key={index}>
                    {({ active }) => (
                      <a
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm no-underline hover:bg-gray-100'
                        )}
                        onClick={(e) => handleMenuItemClick(e, item.label)}
                      >
                        {item.label}
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

        {/* Display the active filter with an "x" button to remove it */}
        {selectedFilter && (
          <div className="flex items-center space-x-2 bg-gray-100 px-2 py-1 rounded-md">
            <span>{selectedFilter}</span>
            <button
              onClick={handleRemoveFilter}
              className="text-gray-600 hover:text-gray-900 focus:outline-none"
            >
              <XMarkIcon className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterDropdown;
