import { useNavigate, useLocation } from 'react-router-dom';
import {useState, useEffect} from 'react';
import './authenticate.css';  
import '../../../css/mobileDriverOnboard.css';  
import LogoMobile from "../../logoMobile";
import { gql, useLazyQuery } from '@apollo/client';

import DECODE_TOKEN from "../../../queries/decodeToken";

// const VERIFY_EMPLOYEE_TOKEN_AND_OTP = gql`
//   query VerifyEmployeeTokenAndOtp($token: String, $otp: String) {
//     verifyEmployeeTokenAndOtp(token: $token, otp: $otp) {
//       success
//       message
//     }
//   }
// `;

const VERIFY_OTP = gql`
  query VerifyOtp($entityId: ID!, $enteredOtp: String!, $entityType: String!) {
    verifyOtp(entityId: $entityId, enteredOtp: $enteredOtp, entityType: $entityType) {
      success
      message
    }
  }
`;


function MobileAuthenticate(): JSX.Element {
  const [otp, setOtp] = useState('');  // State to store the OTP
  const [token, setToken] = useState('');  // State to store the JWT token
  const [firstName, setFirstName] = useState('');  // State to store the first name
  const [errorMessage, setErrorMessage] = useState('');  // State to store the error message

  const navigate = useNavigate();
  const location = useLocation();  // Get the current location object

  // const [verifyTokenAndOtp, { data, loading, error }] = useLazyQuery(VERIFY_EMPLOYEE_TOKEN_AND_OTP);
  const [decodeToken, { data: decodeData }] = useLazyQuery(DECODE_TOKEN)
  const [verifyOtp, { data: verifyOtpData }] = useLazyQuery(VERIFY_OTP);
  useEffect(() => {
    // Extract the token from the URL when the component mounts
    const params = new URLSearchParams(location.search);
    const tokenFromUrl = params.get('token');
    const name = params.get('name');
    
    if (name) {
      setFirstName(name)
    }

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      } else {
        alert('Invalid session. Please use the link provided in your email.');
      }
      }, [location.search]);

  const handleSubmit = async () => {
      try {
        // const response = await verifyTokenAndOtp({
        //   variables: { token, otp }
        // });
        const decodeResponse = await decodeToken({
          variables: { token }
        })

        if (!decodeResponse.data.decodeToken.userId) {
          setErrorMessage(decodeResponse.data.decodeToken.message || 'Invalid or expired token.');
          return;
      }

      const entityId = decodeResponse.data.decodeToken.userId;
      //set context here with userId
      const entityType = 'employee';  // or 'user', depending on your context


        // Step 2: Verify OTP
        const verifyOtpResponse = await verifyOtp({
          variables: { entityId, enteredOtp: otp, entityType }
      });

      if (verifyOtpResponse.data.verifyOtp.success) {
          navigate('/mobileDriverOnboard/terms-conditions');
      } else {
          setErrorMessage(verifyOtpResponse.data.verifyOtp.message || 'Whoops, that passcode does not match. Give it another go!');
      }
  } catch (err) {
      console.error('Error during verification:', err);
      alert('An error occurred. Please try again.');
  }
    };

  return (
    <div className='mobile-driver-onboard mobile-main'>
      <div className="mobile-header-goat">
        <div className="logo-size">
          <LogoMobile />
        </div>
        <div className="welcome-message">
          <h1>Welcome to Trulieve,</h1>
          <h1>{firstName}!</h1>
        </div>
      </div>
      <div className="mobile-body authenticate-body">
        <div className="input-container">
        <input 
          type="text" 
          id="otp" 
          placeholder="Enter Your Passcode" 
          value={otp}
          onChange={(e) => setOtp(e.target.value)}  // Update state on input change
        />
        <div style={{ color: 'red', fontSize: '11px' }}>{errorMessage}</div>
        </div>
        <div className="authenticate-button-container">
          <button 
            className="submit-button"
            onClick={handleSubmit}
          >SUBMIT</button>
          {/* <button className="cancel-button">CANCEL</button> */}
        </div>
      </div>
    </div>
  );
}

export default MobileAuthenticate;
