
import { gql } from '@apollo/client';

const FIND_EMAIL = gql`
  query FindEmail($email: String!) {
    findEmail(email: $email) {
      _id
      email
    }
  }
`;

export default FIND_EMAIL;
