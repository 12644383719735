import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import MainTemplate from '../components/mainTemplate';
import PageTitle from '../components/pageTitle/index';
import Table from '../components/table/index';
import SlideOver from '../components/rightSlideOverFeed1';
import DashboardIcon from '../assets/dashboardIcon.svg';
import { CalendarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import { Circles } from 'react-loader-spinner';
import '../css/App.css';
import waterscooter from '../assets/storySetIllustrations/Water scooter-pana.png'

import GET_EMPLOYEES_WITH_OUTLIERS from '../queries/dashboardOutlier';
import FIND_EMPLOYEE_FFDSTATUS from '../queries/getEmployeeByFfdStatus';
import { useQuery } from '@apollo/client';

import formatDate from '../helpers/formatDate';
import { useOverdueTasks } from '../context/overdueTasksContext';

interface Criterion {
  _id: string;
  description: string;
  deadline: string;
}

interface Program {
  _id: string;
  criteria: Criterion[];
  name: string;
  status: string;
}

interface LocationReference {
  _id: string;
  name: string;
  addressState: string;
}

interface Employee {
  _id: string;
  location: string;
  outlierCount: number;
  firstName: string;
  lastName: string;
  locationReference: LocationReference[];
  program: Program[];
  driversLicenseState: string;
  driversLicenseExpiration: string;
  driversLicenseNum: string;
  emailCompany: string;
  phone: string;
  addressState: string;
  ffdStatus: string;
}

// Define the custom overlay template
const overlayNoRowsTemplate = `
  <div style="text-align: center;">
    <h2 style="font-weight: bold; font-size: 32px; padding-top:10rem;">You're set!</h2>
    <img src="${waterscooter}" alt="Fun Graphic" style="width: 600px;" />
  </div>
`;

const Dashboard = () => {
  const [showOverdueTasks, setShowOverdueTasks] = useState(false);
  const [showWeekTasks, setShowWeekTasks] = useState(false);
  const [showMonthTasks, setShowMonthTasks] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [isListHovered, setIsListHovered] = useState(false);
  const [isDashHovered, setIsDashHovered] = useState(false);
  const [showAllTasks, setShowAllTasks] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);


  const location = useLocation()
  const navigate = useNavigate()

  const { data, error, loading } = useQuery(FIND_EMPLOYEE_FFDSTATUS, {
    variables: { ffdStatus: "PENDING" }, // For example, to find employees with ffdStatus "PENDING"
  });

  const { overdueTasks, weekTasks, monthTasks } = useOverdueTasks();

  const transformEmployeeData = (employee: Employee) => {
    console.log("employee", employee)
    return employee.program.map((program) => {

      return {
        ID: employee._id,
        //please map through location references for id
        LOCATION_ID: employee.locationReference[0]._id,
        TYPE: program.name, 
        DRIVER: `${employee.firstName} ${employee.lastName}`,
        STATUS: employee.ffdStatus, 
        //please map through location references for name
        LOCATIONS: employee.locationReference[0].name,
        EMAIL: employee.emailCompany,
        NAME: `${employee.firstName} ${employee.lastName}`,
        STATE:employee.addressState,
        LICENSE_NUMBER: employee.driversLicenseNum,
        LICENSE_EXPIRATION: employee.driversLicenseExpiration,
        PROGRAM:employee.program, 
        // OUTLIER_COUNT: employee.outlierCount,
      };
    });
  };

  //memoization for returning context (or query data) for rowData information to display in table 
  //-- context is used when user clicks a stat from dashboardAnalytics
  let rowData = useMemo(() => {
    if(showOverdueTasks) {
      return overdueTasks;
    }
    if(showWeekTasks) {
      return weekTasks;
    }
    if(showMonthTasks) {
      return monthTasks;
    }
    else if (data && data.findEmployeeFfdStatus) {
      return data.findEmployeeFfdStatus.flatMap(transformEmployeeData);
    }
    return [];
  }, [showOverdueTasks, overdueTasks, showWeekTasks, weekTasks, showMonthTasks, monthTasks, data]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('showOverdueTasks') === 'true') {
      setShowOverdueTasks(true);
    }
    if (queryParams.get('showWeekTasks') === 'true') {
      setShowWeekTasks(true);
    }
    if (queryParams.get('showMonthTasks') === 'true') {
      setShowMonthTasks(true);
    }
    console.log("selectedRowData", selectedRowData)
  }, [location, selectedRowData]);

  const handleRowClick = (event: any) => {
    setSelectedRowData(event.data);
    setPopoverVisible(true);
  };

  const handleEmployeeRowClick = (event: any) => {
    const employeeId = event.data.ID;
    navigate(`/employeeDetail/${employeeId}`);
  };

  const handleLocationRowClick = (event: any) => {
    const locationId = event.data.LOCATION_ID;
    console.log("locationId", locationId)
    navigate(`/detailLocation/${locationId}`);
  };

  useEffect(() => {
    if (!popoverVisible) {
      setSelectedRowData(null);
    }
  }, [popoverVisible]);

  const handleListIconMouseEnter = () => {
    setIsListHovered(true);
  };

  const handleListIconMouseLeave = () => {
    setIsListHovered(false);
  };

  const handleDashIconMouseEnter = () => {
    setIsDashHovered(true);
  };

  const handleDashIconMouseLeave = () => {
    setIsDashHovered(false);
  };

  const handleDashIconClick = () => {
    navigate('/dashboardAnalytics');
  };

  const columnDefs = [
    { field: 'TYPE', flex: 1, cellClass: 'underline-location', onCellClicked: handleRowClick },
    { field: 'DRIVER', sortable: true,
      comparator: (valueA: any, valueB: any) => {
        const lastNameA = valueA.split(' ').slice(-1)[0]; // Get the last word (last name)
        const lastNameB = valueB.split(' ').slice(-1)[0];
        
        if (lastNameA === lastNameB) return 0;
        return lastNameA > lastNameB ? 1 : -1;
      },flex: 1, cellClass: 'underline-location', onCellClicked: handleEmployeeRowClick },
    { field: 'STATUS', flex: 1 },
    { field: 'LOCATIONS', flex: 1, cellClass: 'underline-location', onCellClicked: handleLocationRowClick },
    { field: 'STATE', flex: 1 },
    // { field: 'DATE', flex: 1 }
  ];

  const navItems = [
    { label: 'REQUIRED INFO', viewId: 'requiredInfo' },
    { label: 'DRIVER\'S LICENSE', viewId: 'driver' },
    { label: 'INSURANCE', viewId: 'history' },
  ];

  
  if (loading) {
    return (
      <>
        <MainTemplate />
        <div className="main-container">
          <div className="header">
            <div className="title-icons-container">
              <div className="logo-container locations-icon">
                <img src={DashboardIcon} alt="Logo" />
              </div>
              <PageTitle pageTitle="Dashboard" />
            </div>
          </div>
          <div className="table-container">
            <div className="loading-spinner">
              <Circles
                height="80"
                width="80"
                color="#007E9D"
                ariaLabel="circles-loading"
                visible={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <MainTemplate />
      <div className="main-container">
          <div className="header">
            <div className="title-icons-container">
              <div className="logo-container locations-icon">
                <img src={DashboardIcon} alt="Logo" />
              </div>
              <PageTitle pageTitle="Dashboard" />
              <div 
                className="map-icon-location"
                style={{ marginLeft: '1vw', marginTop: '2.25vh' }}
              >
                <div
                  onMouseEnter={handleDashIconMouseEnter}
                  onMouseLeave={handleDashIconMouseLeave}
                  onClick={handleDashIconClick}
                >
                  {isDashHovered ? (
                    <CalendarIcon className="cursor-pointer h-6 w-6 text-gray-500" />
                  ) : (
                    <CalendarIcon className="cursor-pointer h-6 w-6 text-gray-400" />
                  )}
                </div>
                <div
                  onMouseEnter={handleListIconMouseEnter}
                  onMouseLeave={handleListIconMouseLeave}
                >
                  {isListHovered ? (
                    <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
                  ) : (
                    <ListBulletIcon 
                      className="cursor-pointer h-6 w-6 text-gray-400" 
                      style={{ color: '#e32661' }}
                    />
                  )}
                </div>
              </div>
              <div className='add-icon'></div>
            </div>
          </div>
          {(showOverdueTasks || showWeekTasks || showMonthTasks || showAllTasks) && (
          <div className="button-container">
            <button 
              className={`filter-buttons-tasks ${showOverdueTasks ? 'outline-magenta' : ''}`}
              onClick={() => {
                setShowAllTasks(false);
                setShowOverdueTasks(true);
                setShowWeekTasks(false);
                setShowMonthTasks(false);
              }}
            >
              Overdue Tasks
            </button>
            <button 
              className={`filter-buttons-tasks ${showWeekTasks ? 'outline-magenta' : ''}`}
              onClick={() => {
                setShowAllTasks(false);
                setShowOverdueTasks(false);
                setShowWeekTasks(true);
                setShowMonthTasks(false);
              }}
              >
              Upcoming This Week
            </button>
            <button 
             className={`filter-buttons-tasks ${showMonthTasks ? 'outline-magenta' : ''}`}
             onClick={() => {
              setShowAllTasks(false);
              setShowOverdueTasks(false);
              setShowWeekTasks(false);
              setShowMonthTasks(true);
            }}
             >
              Upcoming This Month
            </button>
            <button 
             className={`filter-buttons-tasks ${showAllTasks ? 'outline-magenta' : ''}`}
             onClick={() => {
              setShowAllTasks(true);
              setShowOverdueTasks(false);
              setShowWeekTasks(false);
              setShowMonthTasks(false);
            }}
             >
              Show All
            </button>
          </div>
          )}
          <div className="table-container">
            <Table 
              columnDefs={columnDefs}
              rowData={rowData}
              onRowClick={handleRowClick}
              overlayNoRowsTemplate={overlayNoRowsTemplate} 
            />
          </div>
        {popoverVisible && selectedRowData && (
          <SlideOver
            title={selectedRowData.TYPE}
            emailCompany={selectedRowData.EMAIL}
            phone={selectedRowData.PHONE}
            name={selectedRowData.NAME}
            employeeId={selectedRowData.ID}
            location={selectedRowData.LOCATIONS}
            licenseNumber={selectedRowData.LICENSE_NUMBER}
            licenseExpiration={selectedRowData.LICENSE_EXPIRATION}
            programArray={selectedRowData.PROGRAM}
            // criterionId={selectedRowData.CRITERION_ID}
            flyOut="none"
            isActive={true}
            itemCount={0}
            activeIndex={activeIndex}
            closePopover={() => setPopoverVisible(false)}
            toggleEdit={() => {}}
            navItems={navItems}
            employeeid={selectedRowData.ID}
          />
        )}
      </div>
    </>
  );
};

export default Dashboard;
