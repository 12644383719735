import { gql } from '@apollo/client';

const ADD_PROGRAM = gql`
  mutation addProgram ($input: ProgramInput!){
    addProgram (input: $input){
        _id
        name
        status
        operator
        # grab userId from sessions
        # createdBy{
        #     _id
        # }
        # add rules to criteria -- when to reach out to employees or managers about upcoming expiration
    }
  }
`;

export default ADD_PROGRAM;
export { ADD_PROGRAM };