import { useState, useEffect } from 'react';
// import './requiredInfo.css';
import { useQuery } from '@apollo/client';
import GET_EMPLOYEE from '../../queries/getEmployee';

interface RequiredInfoComponentProps {
  employeeid: number;
  onMobileApprovalFetched: (mobileApproval: any[]) => void;
}

const RequiredInfoComponent = ({
  employeeid,
  onMobileApprovalFetched
}: RequiredInfoComponentProps) => {

    const { data, loading, error } = useQuery(GET_EMPLOYEE, {
        variables: { id: employeeid },
    })
    // const [formData, setFormData] = useState({
    //     firstName: '',
    //     lastName: '',
    //     streetAddress: '',
    //     city: '',
    //     state: '',
    //     zip: '',
    //     gender: '',
    //     maritalStatus: '',
    //     emergencyContact: '',
    //     relationship: '',
    //     phoneNumber: ''
    //   });
    //   console.log("data", data);
  // Use useEffect to populate formData once the employee data is fetched

  useEffect(() => {
    if (data?.getEmployeeById) {
      const employee = data.getEmployeeById;
      console.log("employee", employee);
      // Check if mobileApproval exists and has documents
      if (employee.mobileApproval && employee.mobileApproval.length > 0) {
        // Send mobileApproval array back to the parent
        onMobileApprovalFetched(employee.mobileApproval);
      }
    }
  }, [data, onMobileApprovalFetched]);
  // useEffect(() => {
  //   if (data?.getEmployee) {
  //     const employee = data.getEmployee;
  //     // console.log("employee", employee);
  //     // setFormData({
  //     //   // firstName: employee.firstName || '',
  //     //   firstName:data.getEmployeeById.firstName||'',
  //     //   lastName: employee.lastName || '',
  //     //   streetAddress: employee.address?.streetAddress || '',
  //     //   city: employee.address?.city || '',
  //     //   state: employee.address?.state || '',
  //     //   zip: employee.address?.zip || '',
  //     //   gender: employee.gender || '',
  //     //   maritalStatus: employee.maritalStatus || '',
  //     //   emergencyContact: employee.emergencyContact?.name || '',
  //     //   relationship: employee.emergencyContact?.relationship || '',
  //     //   phoneNumber: employee.emergencyContact?.phoneNumber || ''
  //     // });
  //   }
  // }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

//   const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

return (
    <div className="relative z-10">
      <div className="flex h-full flex-col bg-white shadow-xl">
        <div className="flex flex-col p-6">
          <form className="mt-6 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={data.getEmployeeById.firstName}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={data.getEmployeeById.lastName}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Street Address</label>
                <input
                  type="text"
                  name="streetAddress"
                  value={data.getEmployeeById.addressStreet}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">City</label>
                <input
                  type="text"
                  name="city"
                  value={data.getEmployeeById.addressCity}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">State</label>
                <input
                  type="text"
                  name="state"
                  value={data.getEmployeeById.addressState}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">ZIP</label>
                <input
                  type="text"
                  name="zip"
                  value={data.getEmployeeById.addressZip}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Gender</label>
                <input
                  type="text"
                  name="gender"
                  value={data.getEmployeeById.gender}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Marital Status</label>
                <input
                  type="text"
                  name="maritalStatus"
                  value={data.getEmployeeById.maritalStatus}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
            </div>

            <h3 className="text-lg font-medium mt-6">Emergency Contact</h3>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Emergency Contact</label>
                <input
                  type="text"
                  name="emergencyContact"
                  value={data.getEmployeeById.emergencyContactName}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Relationship</label>
                <input
                  type="text"
                  name="relationship"
                  value={data.getEmployeeById.relationshipToEmployee}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={data.getEmployeeById.emergencyContactPhone}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm"
                  readOnly
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );;
};

export default RequiredInfoComponent;
