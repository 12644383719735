import './displayData.css';
import React, { useState } from 'react';

interface InformationRow {
  label: string;
  value: string;
}

interface InformationProps {
  sections: {
    title: string;
    data: InformationRow[];
  }[];
  employeeId: string;
  onUpdateInformation: (input: any) => void;
}

const InformationComponent = ({ sections, employeeId, onUpdateInformation }: InformationProps) => {
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState(sections); // Use formData for input values

  const handleInputChange = (sectionIndex: number, itemIndex: number, newValue: string) => {
    // Update the specific field value in formData
    const updatedFormData = [...formData];
    updatedFormData[sectionIndex].data[itemIndex].value = newValue;
    setFormData(updatedFormData);
  };

  const handleSave = () => {
    onUpdateInformation(formData); // Pass the updated form data to parent component
    console.log('Updated form data:', formData);
    setEditMode(false); // Exit edit mode after saving
  };

  const toggleEdit = () => {
    setEditMode(!editMode); // Toggle edit mode
  };

  return (
    <div className="information-container">
      <button onClick={editMode ? handleSave : toggleEdit} className="edit-button">
        {editMode ? 'Save' : 'Edit'}
      </button>

      <div>
        <dl className="divide-y divide-gray-200">
          {formData.map((section, sectionIndex) => (
            <div key={sectionIndex} id={section.title.replace(/\s+/g, '-').toLowerCase()}>
              <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">{section.title}</h3>
              </div>
              {section.data.map((item, itemIndex) => (
                <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-0 sm:px-0" key={itemIndex}>
                  <dt className="text-sm font-medium leading-6 text-gray-900">{item.label}</dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {editMode ? (
                      <input
                        type="text"
                        value={item.value}
                        onChange={(e) => handleInputChange(sectionIndex, itemIndex, e.target.value)}
                      />
                    ) : (
                      item.value
                    )}
                  </dd>
                </div>
              ))}
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export default InformationComponent;
