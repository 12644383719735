import { gql } from '@apollo/client';

const GET_EMPLOYEE = gql`
  query GetEmployee($id: ID!) {
    getEmployeeById(id: $id) {
        _id
        userId
        position
        firstName
        lastName
        addressStreet
        addressCity
        addressState
        addressZip
        phone
        emailPersonal
        emailCompany
        dob
        gender
        maritalStatus
        emergencyContactName
        relationshipToEmployee
        emergencyContactPhone
        startDate
        stopDate
        hireDate
        createdDate
        lastUpdate
        lastUpdateByUser
        ffdStatus
        mvrDate
        mvrScore
        mvrState
        expirationDate
        employmentStatus
        driversLicenseNum
        driversLicenseExpiration
        location
        locationReference
        {
          # locManagerName
          # locManagerPhone
          # locManagerEmail
          name
          addressStreet
          addressCity
          addressState
          addressZip
        }
        program
        {
          name
          status
          locationName
          criteria{
            _id
            name
            criterionStatus
            description
            deadline
          }
        }
        documentReference{
          documentType
          documentId
          s3Bucket
          s3Key
          s3Url
      }
      mobileApproval{
        approvedData
      }
      vehicleReference{
        vin
        year
        make
        model
        series
      }
    }
  }`

export default GET_EMPLOYEE;
export { GET_EMPLOYEE };