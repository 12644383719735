import { gql } from '@apollo/client';

const FIND_EMPLOYEE_FFDSTATUS = gql`
  query FindEmployeeFfdStatus($ffdStatus: String!) {
    findEmployeeFfdStatus(ffdStatus: $ffdStatus) {
        _id
    
        userId
        position
        firstName
        lastName
        addressStreet
        addressCity
        addressState
        addressZip
        phone
        emailPersonal
        emailCompany
        dob
        gender
        maritalStatus
        emergencyContactName
        relationshipToEmployee
        emergencyContactPhone
        startDate
        stopDate
        hireDate
        createdDate
        lastUpdate
        lastUpdateByUser
        ffdStatus
        mvrDate
        mvrScore
        mvrState
        expirationDate
        employmentStatus
        driversLicenseNum
        driversLicenseExpiration
        location
        program
        {
            _id
          status
          name
          locationName
          criteria{
            _id
            name
            criterionStatus
            description
            deadline
          }
        }
        documentReference{
          documentType
          documentId
          s3Bucket
          s3Key
          s3Url
      }
      locationReference{
        _id
        name
      }
    }
  }`

export default FIND_EMPLOYEE_FFDSTATUS;
export { FIND_EMPLOYEE_FFDSTATUS };