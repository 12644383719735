
interface Stat {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
}

interface StatsComponentProps {
  stats: Stat[];
}

// Define the Example component with proper types
function StatsComponent({ stats}: StatsComponentProps): JSX.Element {

  return (
    <div>
      <dl className="mt-5 mb-12 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-0">
            <dt className="text-base font-bold text-gray-600 ml-2 mt-1 text-center uppercase">{item.name}</dt>
            <dd className="mt-1 flex items-center justify-center md:block lg:flex">
              <div
                className="ml-2 mb-1 flex items-center justify-center text-xl font-semibold cursor-pointer"
                style={{ color: '#007e9d' }}
              >
                {item.stat}
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}

export default StatsComponent;