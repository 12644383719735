import { gql } from '@apollo/client';

const GENERATE_NEW_OTP = gql`
  mutation generateNewOTP($id: ID!, $email: String!, $name: String!) {
    generateNewOTP(id: $id, email: $email, name: $name) {
      success
      message
    #   otpExpiration
    }
  }
`;

export default GENERATE_NEW_OTP;
export { GENERATE_NEW_OTP };
