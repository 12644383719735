import { gql } from '@apollo/client';

const GENERATE_USER_OTP = gql`
  mutation GenerateUserOtp($userId: ID!, $email: String!) {
    generateUserOTP(userId: $userId, email: $email) {
      success
      message
      otp
    }
  }
`;

export default GENERATE_USER_OTP;
