import { useState } from 'react';
import GENERATE_NEW_OTP from '../../queries/generateNewOtp';
import { useMutation } from '@apollo/client';

type GenerateNewOtpProps = {
  employeeEmail: any;
  id: any;
  name: any;
};

const GenerateNewOtp = ({ employeeEmail, id, name }: GenerateNewOtpProps) => {
  console.log('employeeEmail, id, name:', employeeEmail, id, name);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const [updateEmployee] = useMutation(GENERATE_NEW_OTP);

  const handleGenerateOtp = async () => {
    setLoading(true);
    setErrorMessage(null);
    setSuccessMessage(null);

    try {
      const { data } = await updateEmployee({
        variables: {
          id,
          email: employeeEmail,
          name,
        },
      });

      if (data?.generateNewOTP?.success) {
        setSuccessMessage(data.generateNewOTP.message || 'OTP generated successfully!');
      } else {
        setErrorMessage(data?.generateNewOTP?.message || 'Failed to generate OTP.');
      }
    } catch (error: any) {
      console.error('Error generating new OTP:', error);
      setErrorMessage(error.message || 'An unexpected error occurred.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full">
      {/* Conditionally render the UI */}
      {!successMessage && !errorMessage && (
        <>
          <h2 className="text-lg font-semibold text-gray-800">
            Generate New One Time Code
          </h2>
          <p className="text-sm text-gray-500">
            If your driver's code has expired, click below and they will receive a
            new email with a code to access the onboarding process securely.
          </p>
          <button
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded update-btn"
            style={{ cursor: 'pointer' }}
            onClick={handleGenerateOtp}
            disabled={loading}
          >
            {loading ? 'Generating...' : "Let's Go"}
          </button>
        </>
      )}

      {/* Display Success Message */}
      {successMessage && (
        <div className="mt-4 p-2 text-gray-700 rounded">
          {successMessage}
        </div>
      )}

      {/* Display Error Message */}
      {errorMessage && (
        <div className="mt-4 p-2 text-red-700 rounded">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default GenerateNewOtp;
