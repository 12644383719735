import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import React, { useState, FormEvent } from 'react';
import '../css/App.css';
import Login from '../components/login/index'
import { gql, useMutation } from '@apollo/client';

const AUTHENTICATE_USER=gql`
  mutation authenticateUser($email: String!, $password: String!){
    authenticateUser(email: $email, password: $password){
      token
      user{
        _id
        email
        role
      }
    }
  }
`;

function LoginPage() {

const [email, setEmail] = useState<string>('');
const [password, setPassword] = useState<string>('');
const [errorMessage, setErrorMessage] = useState<string>('');

const navigate = useNavigate();

const [authenticateUser, {data, loading, error}] = useMutation(AUTHENTICATE_USER
);
if (loading) return <p>Loading...</p>;
// if (error) return <p>Error: {error.message}</p>;

const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setEmail(event.target.value);
};

const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  setPassword(event.target.value);
};

const handleSubmit = async (event:any)=> {
  setErrorMessage('');
  event.preventDefault(); 
  try{
    const {data} = await authenticateUser({
      variables : {
        email: email, 
        password: password, 
      },
    });
    console.log("Authentication success:", data);
    if (data && data.authenticateUser && data.authenticateUser.token) {
      if(data.authenticateUser.user.role === 'Admin'){
        navigate('/location')
      }else{
        navigate('/dashboard')}
    }    
  }catch(error){
    console.error('Authentication error:', error);
    // Update error message when authentication fails
    setErrorMessage('Looks like your password or email is incorrect. Try running it again.');
  }
}
  return (
    <>
    {/* {errorMessage && <p className="error-message">{errorMessage}</p>} */}
      <Login
        email={email}
        password={password}
        handleEmailChange={handleEmailChange}
        handlePasswordChange={handlePasswordChange}
        handleSubmit={handleSubmit}
        errorMessage={errorMessage} 
      />
    </>
  );
}

export default LoginPage;
