import { gql } from '@apollo/client';

const GET_LOCATIONS_BY_IDS = gql`
query GetLocationsByIds($ids: [ID!]!) {
    getLocationsByIds(ids: $ids) {
      _id
      name
    }
  }
`;

export default GET_LOCATIONS_BY_IDS;
export { GET_LOCATIONS_BY_IDS };
