import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import GET_ALL_EMPLOYEES from '../queries/getAllEmployees';

import MainTemplate from '../components/mainTemplate';
import StatsDashboardAnalytics from '../components/statsDashboardAnalytics/index';
import PageTitle from '../components/pageTitle';
import Table from '../components/table';
import ChartComponent from '../components/chart/index';
import DashboardIcon from '../assets/dashboardIcon.svg';

import { CalendarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import '../css/App.css';
import '../components/statsDashboard/statsDashboard.css';

interface Stat {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: 'increase' | 'decrease';
}
interface PieChartDataPoint {
  name: string;
  y: number;
}

interface Employee {
  _id: string;
  location: string;
  outlierCount: number;
  firstName: string;
  lastName: string;
  locationReference: LocationReference[];
  driversLicenseState: string;
  driversLicenseExpiration: string;
  driversLicenseNum: string;
  emailCompany: string;
  phone: string;
  addressState: string;
  ffdStatus: string;
}

interface LocationReference {
  _id: string;
  name: string;
  addressState: string;
}

const DriverAnalytics: React.FC = () => {
  const navigate = useNavigate();

  const [isListHovered, setIsListHovered] = useState(false);
  const [isDashHovered, setIsDashHovered] = useState(false);
  const {data, error, loading }=useQuery(GET_ALL_EMPLOYEES);

  // Filter and map through the employees with ffdStatus === "ACTIVE"
const activeEmployees = data?.getAllEmployees.filter((employee: { ffdStatus: string; }) => employee.ffdStatus === 'ACTIVE');
const inProgressEmployees = data?.getAllEmployees.filter((employee: { ffdStatus: string; }) => employee.ffdStatus === null);
const readyToReviewEmployees = data?.getAllEmployees.filter((employee: { ffdStatus: string; }) => employee.ffdStatus === 'PENDING');
const overdueEmployees = data?.getAllEmployees.filter((employee: { startDate: string | number | Date; }) =>
  isSevenDaysOrMorePast(employee.startDate)
)
console.log('pending', readyToReviewEmployees);

const inProgressCount = inProgressEmployees?.length;
const readyToReviewCount = readyToReviewEmployees?.length;
const activeCount = activeEmployees?.length;
const overdueCount = overdueEmployees?.length;

function isSevenDaysOrMorePast(startDate: string | number | Date) {
  // Get the current date (ignoring the time)
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const parsedStartDate = new Date(startDate);
  parsedStartDate.setHours(0, 0, 0, 0);
  const differenceInTime = Number(currentDate) - Number(parsedStartDate);
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays >= 7;
}

// Years to display on the x-axis
const chartCategoriesYears = ['2022', '2023', '2024'];

// Sample data for each status over the years
const chartDataByStatus: { name: string; y: number }[] = [
  { name: 'Onboarded', y: 30 },
  { name: 'Expired', y: 15 },
  { name: 'Active', y: 20 },
];

  const chartTitle1 = 'ONBOARDED DRIVERS';
  const chartTitlePie = 'DRIVERS BY STATUS';
  const chartCategoriesPie = ['Active', 'Pending', 'In Process', 'Inactive'];
  const chartDataPieValues = [activeCount, readyToReviewCount, inProgressCount, overdueCount];
  const chartDataPie: PieChartDataPoint[] = chartCategoriesPie.map((name, index) => ({
    name: name,
    y: chartDataPieValues[index]
  }));

  const stats: Stat[] = [
    {
      name: 'IN PROGRESS', 
      stat: inProgressCount,
      previousStat: '',
      change: '',
      changeType: 'increase'
    },
    { 
      name: 'READY TO REVIEW', 
      stat: readyToReviewCount, 
      previousStat: '', 
      change: '', 
      changeType: 'increase' 
    },
    { 
      name: 'OVERDUE', 
      stat: overdueCount, 
      previousStat: '', 
      change: '', 
      changeType: 'increase' 
    },
  ];

  const transformEmployeeData = (employee: Employee) => {
    return {
      ID: employee._id,
      LOCATION_ID: employee.locationReference[0]?._id || 'N/A', // Optional chaining for safety
      DRIVER: `${employee.firstName} ${employee.lastName}`,
      STATUS: employee.ffdStatus || 'N/A',
      LOCATIONS: employee.locationReference[0]?.name || 'N/A', // Optional chaining for safety
      EMAIL: employee.emailCompany || 'N/A',
      NAME: `${employee.firstName} ${employee.lastName}`,
      STATE: employee.addressState || 'N/A',
      LICENSE_NUMBER: employee.driversLicenseNum || 'N/A',
      LICENSE_EXPIRATION: employee.driversLicenseExpiration || 'N/A',
    };
  };
  

  const columnDefs = [
    { field: 'DRIVER', sortable: true,
      comparator: (valueA: any, valueB: any) => {
        const lastNameA = valueA.split(' ').slice(-1)[0]; // Get the last word (last name)
        const lastNameB = valueB.split(' ').slice(-1)[0];
        
        if (lastNameA === lastNameB) return 0;
        return lastNameA > lastNameB ? 1 : -1;
      },flex: 1, cellClass: 'underline-location'},
    { field: 'STATUS', flex: 1 },
    { field: 'LOCATIONS', flex: 1, cellClass: 'underline-location'},
    { field: 'STATE', flex: 1 },
    // { field: 'DATE', flex: 1 }
  ];

  const rowData = readyToReviewEmployees?.map(transformEmployeeData)|| [];
  console.log(rowData);

  const handleListIconMouseEnter = () => {
    setIsListHovered(true);
  };

  const handleListIconMouseLeave = () => {
    setIsListHovered(false);
  };

  const handleDashIconMouseEnter = () => {
    setIsDashHovered(true);
  };

  const handleDashIconMouseLeave = () => {
    setIsDashHovered(false);
  };

  return (
    <div className="">
      <MainTemplate />
      <div className="main-container">
        <div className="header">
          <div className="title-icons-container">
            <div className="logo-container locations-icon">
              <img src={DashboardIcon} alt="Logo" />
            </div>
            <PageTitle pageTitle="Dashboard" />
            <div className="map-icon-location" style={{ marginTop: '2.25vh', marginLeft: '1vw' }}>
              <div
                onMouseEnter={handleDashIconMouseEnter}
                onMouseLeave={handleDashIconMouseLeave}
              >
                {isDashHovered ? (
                  <CalendarIcon className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow" />
                ) : (
                  <CalendarIcon
                    className="cursor-pointer h-6 w-6 text-gray-400 pomegranate-glow"
                    style={{ color: '#e32661' }}
                  />
                )}
              </div>
              <div
                onMouseEnter={handleListIconMouseEnter}
                onMouseLeave={handleListIconMouseLeave}
                onClick={() => navigate('/dashboard')}
              >
                {isListHovered ? (
                  <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-500" />
                ) : (
                  <ListBulletIcon className="cursor-pointer h-6 w-6 text-gray-400 " />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="ml-1 flex-1 mb-4 overflow-auto">
          <StatsDashboardAnalytics stats={stats}/>
          <div className="">
            <h1 style={{color:'gray', paddingBottom:'.5rem', fontWeight:'bold'}}>DRIVERS AWAITING APPROVAL</h1>
            <div style={{ height:'160px'}}>
              <Table columnDefs={columnDefs} rowData={rowData} />
            </div>
           <br/>
           <br/>
            <div className="flex justify-center">
              <div 
                className="grid grid-cols-2"
                style = {{gap: '6rem'}}
              >
                <div style={{ width: '100%', height: 'auto' }}>
                  <ChartComponent
                    title={chartTitle1}
                    categories={chartCategoriesYears}
                    data={chartDataByStatus}
                    type='column'
                    colors={['#E32661']}
                    yAxisValue=''
                  />
                </div>
                <div style={{ width: '100%', height: 'auto' }}>
                  <ChartComponent
                    title={chartTitlePie}
                    data={chartDataPie}
                    type='pie'
                    colors={['#007E9D', '#E32661', '#FFBF59', '#3B5B80', '#F4F0E8', '#FFB417', '#555554']}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverAnalytics;
