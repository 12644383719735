import { gql } from '@apollo/client';

const FIND_DOCUMENT = gql`
    query FindDocument($id: ID!) {
        findDocumentById(_id: $id) {
            _id 
            administrativeMetadata{
                creationDate
            }
            technicalMetadata{
                fileType
            }
            s3Reference {
                s3Bucket
                s3Key
                s3URL
            }
        }
    }
`;
export default FIND_DOCUMENT;
export { FIND_DOCUMENT };