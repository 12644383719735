import { gql } from '@apollo/client';

const SEND_CUSTOM_EMAIL = gql`
  mutation SendCustomEmail(
    $recipientEmail: String!, 
    $senderEmail: String!, 
    $subject: String!, 
    $bodyContent: String!
  ) {
    sendCustomEmail(
      recipientEmail: $recipientEmail, 
      senderEmail: $senderEmail, 
      subject: $subject, 
      bodyContent: $bodyContent
    ) {
      success
      message
    }
  }
`;

export default SEND_CUSTOM_EMAIL;