import { Disclosure } from '@headlessui/react'
import Stats from '../statsDashboard';
import Chart from '../chart';
import { FC } from 'react';
import {
    ChevronDownIcon,
    ChevronUpIcon,
  } from '@heroicons/react/20/solid';
import { useState } from 'react';
import  './programPerformance.css'

  
interface Stat {
    name: string;
    stat: string;
    previousStat: string;
    change: string;
    changeType: 'increase' | 'decrease';
  }

const ProgramPerformance: FC =() => {

    const stats: Stat[] = [
        { name: 'TASKS COMPLETED', stat: '25', previousStat: '70,946', change: '12%', changeType: 'increase' },
        { name: 'FOLLOW-UPS AUTOMATED', stat: '18', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
        { name: 'HOURS SAVED', stat: '22', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
      ];


    const [isExpanded, setIsExpanded] = useState(false);

      const toggleExpand = () => {
        setIsExpanded(!isExpanded);
      };

    return (
        <>
        <div className="flex items-center mt-3">
        <h2 className="text-xl font-semibold">Performance</h2>
        <button onClick={toggleExpand} className="text-gray-500 hover:text-gray-700">
          {isExpanded ? (
            <ChevronUpIcon className="h-8 w-8" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-8 w-8" aria-hidden="true" />
          )}
        </button>
      </div>
      {isExpanded && (
        <div className="">
        <div className="">
            <Stats stats={stats} onInProgressClick={() => {}} onReviewClick={() => {}} onMonthTasksClick={() => {}} />
        </div>
        <div className="charts-container">
            <Chart title={'Fit For Duty'} categories={['Fit', 'Unfit']} data={[3, 22]} type={'pie'} colors={['#791e94', '#e5bbf1']} />
            <Chart yAxisValue={""} title={'ACTION NEEDED BY TYPE'} categories={['Driver Profile', 'Samba Enrollment', 'Samsara Enrollment', 'Training Complete']} data={[15, 25, 51, 91]} type={'column'} colors={['#e32661']} />
        </div>
    </div>
    
    )}
        </>
    );
  }

  export default ProgramPerformance;
  